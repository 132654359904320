import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ArticlesStore } from '../../stores/ArticlesStore';
import ArticleContent from '../Body/Article/ArticleContent';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { RootStore } from 'stores/RootStore';

type PathParamsType = {
  categoryId: string;
  searchText: string;
  articleId: string;
  projectId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  articlesStore?: ArticlesStore;
  rootStore?: RootStore;
};

@inject('articlesStore', 'rootStore')
@observer
class SearchArticleComponent extends React.Component<Props> {
  componentDidMount(): void {
    // eslint-disable-next-line
    this.props.articlesStore!.setCurrentArticle(this.props.match!.params.articleId);
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    // eslint-disable-next-line
    const artic = this.props.articlesStore!.articleById(this.props.match!.params.articleId);
    if (project && artic) {
      document.title = `${project.data().name} | ${artic.data().name}`;
    }
  }

  componentDidUpdate(prevProps: Props): void {
    // eslint-disable-next-line
    const { articleId } = this.props.match!.params;
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    document.title = articleId;
    // eslint-disable-next-line
    const { articleId: prevArticleId } = prevProps.match!.params;
    if (articleId !== prevArticleId) {
      // eslint-disable-next-line
      this.props.articlesStore!.setCurrentArticle(articleId);
    }

    // eslint-disable-next-line
    const artic = this.props.articlesStore!.articleById(articleId);
    if (project && artic) {
      document.title = `${project.data().name} | ${artic.data().name}`;
    }
  }

  render(): React.ReactNode {
    return (
      <ArticleContent
        // eslint-disable-next-line
        articleId={this.props.match!.params.articleId}
        // eslint-disable-next-line
        hash={this.props.location!.hash}
        // eslint-disable-next-line
        content={this.props.articlesStore!.currentArticle}
      />
    );
  }
}

export const SearchArticle = withRouter(SearchArticleComponent);
