import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Label from 'reactstrap/lib/Label';
import { toasts } from 'shared';
import { localizedStrings } from '../../localizedStrings';
import { RootStore } from '../../stores/RootStore';
import './Auth.css';
import { ThemeContext } from '../App/App';
import { ApplicationStyle } from '@eir/core';

interface Props extends RouteComponentProps {
  rootStore?: RootStore;
}
interface State {
  email: string;
  password: string;
  pin: string;
  mode: boolean; // true is admin login mode, false is staff login mode
}
@inject('rootStore')
@observer
class AuthComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      mode: true,
      pin: '',
    };
  }
  componentDidMount(): void {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      document.title = `${project.data().name} | Loggain`;
    }
  }
  componentDidUpdate(prevProps: Props, prevState: State): void {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      document.title = `${project.data().name} | Loggain`;
    }
    // switching from admin to staff login
    if (prevState.mode && !this.state.mode) {
      // eslint-disable-next-line
      this.setState({ email: this.props.rootStore!.staffAccount });
    }
    // switching from staff to admin login
    if (!prevState.mode && this.state.mode) {
      this.setState({ email: '' });
    }
  }

  signIn = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    const { email, password, pin, mode } = this.state;

    const { rootStore, history } = this.props;
    const passCredential = mode ? password : pin;
    // eslint-disable-next-line
    rootStore!
      .signIn(email, passCredential)
      // eslint-disable-next-line
      .then(() => history.replace(`/${rootStore!.projectId}`))
      .catch((err) => {
        let errorMessage = localizedStrings.auth.error.unknown;
        if (['auth/wrong-password', 'auth/user-not-found', 'auth/invalid-email'].includes(err.code)) {
          errorMessage = localizedStrings.auth.error.invalidCredentials;
        }
        toasts.error(errorMessage);
      });
  };

  handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  chageMode = (): void => {
    this.setState({ mode: !this.state.mode });
  };

  render(): React.ReactNode {
    const { mode, email, password, pin } = this.state;
    // eslint-disable-next-line
    const { getStaffAccount } = this.props.rootStore!;
    return (
      <ThemeContext.Consumer>
        {(appStyle: ApplicationStyle): React.ReactNode => {
          return (
            <React.Fragment>
              <div className="d-flex mt-3 justify-content-center">
                <div className="btn-group btn-toggle">
                  <button
                    className={'btn ' + (mode ? ' btn-black ' : 'btn-outline-dark')}
                    style={mode ? { backgroundColor: appStyle.primaryDark, color: 'white' } : {}}
                    onClick={this.chageMode}
                  >
                    Administratör
                  </button>
                  <button
                    className={'btn ' + (!mode ? ' btn-black ' : 'btn-outline-dark')}
                    style={!mode ? { backgroundColor: appStyle.primaryDark, color: 'white' } : {}}
                    onClick={this.chageMode}
                  >
                    Personal
                  </button>
                </div>
              </div>
              <div className="p-3 ml-auto mr-auto col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={this.signIn}>
                      {mode ? (
                        <React.Fragment>
                          <div className="form-group">
                            <label>{localizedStrings.auth.emailTitle}</label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              id="emailInput"
                              onChange={this.handleInputChange}
                              value={email}
                              placeholder={localizedStrings.auth.emailPlaceholder}
                              style={{ backgroundColor: appStyle.primaryLight }}
                            />
                          </div>
                          <div className="form-group">
                            <label>{localizedStrings.auth.passwordTitle}</label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              id="passwordInput"
                              value={password}
                              onChange={this.handleInputChange}
                              placeholder={localizedStrings.auth.passwordPlaceholder}
                              style={{ backgroundColor: appStyle.primaryLight }}
                            />
                          </div>
                          <button
                            className="btn"
                            style={{ backgroundColor: appStyle.primaryDark, color: 'white' }}
                            type="submit"
                          >
                            {localizedStrings.auth.singInButton}
                          </button>
                        </React.Fragment>
                      ) : getStaffAccount ? (
                        <React.Fragment>
                          <div className="form-group">
                            <label>{`Personalkonto lösenord`}</label>
                            <input
                              className="form-control"
                              type="password"
                              name="pin"
                              id="pin"
                              value={pin}
                              onChange={this.handleInputChange}
                              placeholder="Lösenord"
                              style={{ backgroundColor: appStyle.primaryLight }}
                            />
                          </div>
                          <button
                            className="btn"
                            style={{ backgroundColor: appStyle.primaryDark, color: 'white' }}
                            type="submit"
                          >
                            {localizedStrings.auth.singInButton}
                          </button>
                        </React.Fragment>
                      ) : (
                        <Label>{localizedStrings.project.noStaffAccount}</Label>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export const Auth = withRouter(AuthComponent);
