import { ApplicationStyle } from '@eir/core';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Nav, Navbar } from 'reactstrap';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { RootStore } from '../../stores/RootStore';
import { ThemeContext } from '../App/App';
import './TopMenu.css';
import { TopMenuItem } from './TopMenuItem';

type PathParamsType = {
  projectId: string;
  categoryId: string;
  articleId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  rootStore?: RootStore;
  categoriesStore?: CategoriesStore;
};

export interface CustomNav {
  to: string;
  title: string;
  lockExternal?: boolean;
}

export interface CustomNavCategory {
  data: () => { name: string; isProtected: boolean };
}

@inject('rootStore', 'categoriesStore')
@observer
class TopMenuComponent extends React.Component<Props> {
  generateCustomNavCategory(navItem: CustomNav): CustomNavCategory {
    const temp: CustomNavCategory = {
      data: () => ({ name: navItem.title, isProtected: navItem.lockExternal ? navItem.lockExternal : false }),
    };
    return temp;
  }
  // eslint-disable-next-line
  getDefaultNavigation({
    iconStyle,
    linkStyle,
    linkStyleActive,
  }: {
    // eslint-disable-next-line
    iconStyle: any;
    // eslint-disable-next-line
    linkStyle: any;
    // eslint-disable-next-line
    linkStyleActive: any;
  }) {
    const { rootStore, categoriesStore } = this.props;
    // eslint-disable-next-line
    const { categories } = categoriesStore!;
    // eslint-disable-next-line
    const isDefaultNavigation = categories && !rootStore!.customNavigation;

    return (
      isDefaultNavigation &&
      categories.map((category) => (
        <TopMenuItem
          iconStyle={iconStyle}
          style={linkStyle}
          activeStyle={linkStyleActive}
          // eslint-disable-next-line
          key={category.id!}
          category={category}
          // eslint-disable-next-line
          link={`/${rootStore!.projectId}/category/${category.id}`}
        />
      ))
    );
  }

  // eslint-disable-next-line
  getCustomNavigation({
    iconStyle,
    linkStyle,
    linkStyleActive,
  }: {
    // eslint-disable-next-line
    iconStyle: any;
    // eslint-disable-next-line
    linkStyle: any;
    // eslint-disable-next-line
    linkStyleActive: any;
  }) {
    const { rootStore, categoriesStore } = this.props;
    // eslint-disable-next-line
    const { categories } = categoriesStore!;

    // eslint-disable-next-line
    const isCustomNavigation = categories && rootStore!.customNavigation.length > 0;
    return (
      isCustomNavigation &&
      // eslint-disable-next-line
      rootStore!.customNavigation.map((navItem, index) => {
        if (navItem.lockExternal) {
          // eslint-disable-next-line
          this.props.categoriesStore!.addAuthCategory(navItem.to);
        }
        // eslint-disable-next-line
        const navCategory = categories.find((c) => c.id === navItem.to!);
        return navCategory ? (
          <TopMenuItem
            iconStyle={iconStyle}
            activeStyle={linkStyleActive}
            style={linkStyle}
            key={index}
            category={navCategory}
            title={navItem.title}
            // eslint-disable-next-line
            link={`/${rootStore!.projectId}/category/${navItem.to}`}
          />
        ) : (
          <TopMenuItem
            iconStyle={iconStyle}
            activeStyle={linkStyleActive}
            style={linkStyle}
            key={index}
            category={this.generateCustomNavCategory(navItem)}
            title={navItem.title}
            // eslint-disable-next-line
            link={`/${rootStore!.projectId}/external/${navItem.to}`}
          />
        );
      })
    );
  }

  // eslint-disable-next-line
  getChiefNavigation({
    iconStyle,
    linkStyle,
    linkStyleActive,
  }: {
    // eslint-disable-next-line
    iconStyle: any;
    // eslint-disable-next-line
    linkStyle: any;
    // eslint-disable-next-line
    linkStyleActive: any;
  }) {
    const { categoriesStore, rootStore } = this.props;
    return (
      <>
        {
          // eslint-disable-next-line
          categoriesStore!.chiefCategoryList.map((category) => (
            <TopMenuItem
              iconStyle={iconStyle}
              activeStyle={linkStyleActive}
              style={linkStyle}
              key={category.data().orderIndex}
              category={category}
              title={category.data().name}
              // eslint-disable-next-line
              link={`/${rootStore!.projectId}/category/${category.id}`}
            />
          ))
        }
      </>
    );
  }
  // eslint-disable-next-line
  render() {
    const { categoriesStore } = this.props;
    // eslint-disable-next-line
    const { hasCategories, hasChiefCategories } = categoriesStore!;

    return (
      <ThemeContext.Consumer>
        {
          // eslint-disable-next-line
          (appStyle: ApplicationStyle) => {
            const navBarStyle = { backgroundColor: appStyle.primary, color: appStyle.primaryLight };
            const linkStyle = { color: appStyle.primaryLight };
            const linkStyleActive = {
              borderBottom: `0.2rem solid ${appStyle.accent}`,
              borderRadius: '2px',
              fontWeight: 'bolder',

              color: appStyle.accent,
            };
            const iconStyle = {
              color: appStyle.primaryDark,
            };

            return (
              <div>
                <Navbar className="app-nav p-0" style={navBarStyle}>
                  <Nav className="mr-auto">
                    {hasCategories && this.getDefaultNavigation({ iconStyle, linkStyle, linkStyleActive })}
                    {hasCategories && this.getCustomNavigation({ iconStyle, linkStyle, linkStyleActive })}
                    {hasChiefCategories && this.getChiefNavigation({ iconStyle, linkStyle, linkStyleActive })}
                  </Nav>
                </Navbar>
              </div>
            );
          }
        }
      </ThemeContext.Consumer>
    );
  }
}

export const TopMenu = withRouter(TopMenuComponent);
