import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { BodyLayout } from '../../Layout/BodyLayout';
import { SideMenu } from '../../SideMenu/SideMenu';
import { CategoriesStore } from 'stores/CategoriesStore';
import { toasts } from 'shared';
import { localizedStrings } from 'localizedStrings';

type PathParamsType = {
  projectId: string;
  articleId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  articlesStore?: ArticlesStore;
  categoriesStore?: CategoriesStore;
};

@inject('articlesStore')
@observer
class ArticleShortUrlComponent extends React.Component<Props> {
  componentDidMount(): void {
    document.title = `${this.props.match.params.projectId} [${this.props.match.params.articleId}]`;
  }
  componentDidUpdate(): void {
    document.title = `${this.props.match.params.projectId} [${this.props.match.params.articleId}]`;
  }
  render(): React.ReactNode {
    const { articlesStore, match } = this.props;
    // eslint-disable-next-line
    const articleToRoute = articlesStore!.articleById(match.params.articleId);

    const sideMenu = articleToRoute ? (
      <SideMenu categoryId={articleToRoute.data().category} />
    ) : (
      <div> ...loading side menu </div>
    );

    if (articleToRoute) {
      // eslint-disable-next-line
      const url = `/${match.params.projectId}/category/${articleToRoute.data().category}/${articleToRoute.id!}`;
      return <Redirect to={url} />;
    }
    if (!articleToRoute) {
      toasts.warning(localizedStrings.article.notFoundWarning);
      return <Redirect to={`/${match.params.projectId}/`} />;
    }

    return <BodyLayout sideMenu={sideMenu} content={<div> ... loading article content </div>} />;
  }
}

export const ArticleShortUrl = withRouter(ArticleShortUrlComponent);
