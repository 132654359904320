import React from 'react';
import { localizedStrings } from '../../localizedStrings';
import ChangeAdminPassword from './ChangeAdminPassword';
import ChangeStaffPassword from './ChangeStaffPassword';

// eslint-disable-next-line
const AccountMangement = () => {
  return (
    <div className="p-3">
      <h3 className="pb-3">{localizedStrings.settings.accountmng}</h3>
      <hr />
      <ChangeAdminPassword />
      <hr />
      <ChangeStaffPassword />
    </div>
  );
};

export default AccountMangement;
