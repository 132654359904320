import * as React from 'react';
import './style.css';

// eslint-disable-next-line
export const LandingPage = () => {
  return (
    <div className="main ml-auto mr-auto text-light h-100">
      <div className="p-5 mr-auto ml-auto intro-content">
        <h1 className="title">Eir CMS.</h1>
        <p> Welcome to Eir CMS.</p>
        <small> All rights reserved Softwerk AB 2018. &copy; </small>
      </div>
    </div>
  );
};
