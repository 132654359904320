import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, Router } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { RootStore } from '../../../stores/RootStore';
import FrameText from '../../FrameText/FrameText';
import './Article.css';
import { ArticleAdminToolbar } from './ArticleAdminToolbar';
import { ArticleEditor } from './ArticleEditor';
import FabButton from './FabButton';
import { NotSavedArticleModal } from './NotSavedModal';
import { ThemeContext } from '../../App/App';
import { ApplicationStyle } from '@eir/core';
import { CategoriesStore } from 'stores/CategoriesStore';

declare global {
  interface Window {
    getInternalLink: (link: string) => void;
  }
}

type PathParamsType = {
  projectId: string;
  categoryId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  router?: Router;
  content: string;
  articleId: string;
  hash?: string;
  rootStore?: RootStore;
  articlesStore?: ArticlesStore;
  categoriesStore?: CategoriesStore;
};

interface State {
  editStatus: boolean;
  isDirty: boolean;
  content: string;
  contentNotSaved: boolean;
}

@inject('rootStore', 'articlesStore', 'categoriesStore')
@observer
class ArticleContent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editStatus: false,
      isDirty: false,
      content: this.props.content,
      contentNotSaved: false,
    };
  }

  // 'draftExist' differ from 'this.contentNotSaved'
  // in that we use 'draftExist' only when the user get back to article edit from the route
  private draftExist: boolean;

  private draftDataId: string;
  private draftDataContent: string;

  componentDidMount(): void {
    this.setState({
      editStatus: false,
      isDirty: false,
      content: this.props.content,
    });
  }

  componentDidUpdate(prevProps: Props): void {
    // check and store if there is any draft data
    if (prevProps.articleId !== this.props.articleId && this.state.isDirty) {
      this.setState({ contentNotSaved: true });
      this.draftDataContent = this.state.content;
      this.draftDataId = prevProps.articleId;
    }

    if (prevProps.content !== this.props.content) {
      this.setState({
        editStatus: this.draftExist ? true : false,
        content: this.draftExist ? this.draftDataContent : this.props.content,
        isDirty: this.draftExist ? true : false,
      });

      if (this.draftExist) {
        this.draftExist = false;
      }
    }
  }

  toggleEdit = (): void => {
    this.setState({ editStatus: !this.state.editStatus });
  };

  reset = (): void => {
    this.setState({
      editStatus: false,
      isDirty: false,
      content: this.props.content,
      contentNotSaved: false,
    });
  };

  edit = (newContent: string): void => {
    this.setState({ isDirty: true, content: newContent });
  };

  save = (): void => {
    // eslint-disable-next-line
    const isChief = this.props.categoriesStore!.isCategoryChief(this.props.match.params.categoryId) ? true : false;
    const content = this.state.content;
    const isChangelog: boolean = this.props.match.params.categoryId === 'changelog';
    // eslint-disable-next-line
    this.props.articlesStore!.saveArticle(this.props.articleId, isChief, isChangelog, content, null);

    this.setState({
      isDirty: false,
      editStatus: false,
      contentNotSaved: false,
      content,
    });
  };

  cancel = (): void => {
    this.draftExist = true;
    this.props.history.goBack();
    this.setState({
      contentNotSaved: false,
      editStatus: true,
      content: this.draftDataContent,
    });
  };

  saveDraft = (): void => {
    // eslint-disable-next-line
    const isChief = this.props.categoriesStore!.isCategoryChief(this.props.match.params.categoryId) ? true : false;
    const isChangelog: boolean = this.props.match.params.categoryId === 'changelog';
    // eslint-disable-next-line
    this.props.articlesStore!.saveArticle(this.draftDataId, isChief, isChangelog, this.draftDataContent, null);
    this.setState({ contentNotSaved: false });
  };

  discardChanges = (): void => {
    this.setState({ contentNotSaved: false });
  };

  render(): React.ReactNode {
    const { rootStore } = this.props;
    const { isDirty, editStatus, contentNotSaved, content } = this.state;

    return (
      <ThemeContext.Consumer>
        {
          // eslint-disable-next-line
          (appStyle: ApplicationStyle) => {
            return (
              <React.Fragment>
                <div>
                  {
                    // eslint-disable-next-line
                    rootStore!.userCanEdit ? (
                      <ArticleAdminToolbar
                        editStatus={editStatus}
                        isDirty={isDirty}
                        onToggleEdit={this.toggleEdit}
                        onSave={this.save}
                        onReset={this.reset}
                      />
                    ) : null
                  }
                </div>
                <div className="article-container">
                  {
                    // eslint-disable-next-line
                    rootStore!.userExist ? (
                      <NotSavedArticleModal
                        onSave={this.saveDraft}
                        onCancel={this.cancel}
                        isOpen={contentNotSaved}
                        onDiscardChanges={this.discardChanges}
                      />
                    ) : null
                  }

                  {
                    // eslint-disable-next-line
                    editStatus && rootStore!.userExist && (
                      <ArticleEditor
                        className="pb-6"
                        editStatus={editStatus}
                        content={content}
                        onModelChange={this.edit}
                      />
                    )
                  }
                  <FrameText content={content} status={!this.state.editStatus} />
                </div>
                <FabButton appStyle={appStyle} />
              </React.Fragment>
            );
          }
        }
      </ThemeContext.Consumer>
    );
  }
}

export default withRouter(ArticleContent);
