import firebase from 'firebase/app';

class FireStoreInit {
  public async initFirebase(): Promise<InitFirebaseResult> {
    const firebaseInitResponse = await fetch('/__/firebase/init.json');
    const json = await firebaseInitResponse.json();
    firebase.initializeApp(json);

    const auth = firebase.auth();
    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    } catch (err) {
      try {
        await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      } catch (err) {
        await auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
      }
    }

    // init the sore;
    const store = firebase.firestore();
    // const settings = { timestampsInSnapshots: true };
    // store.settings(settings);

    const storage = firebase.storage();

    return Promise.resolve({ fireStore: store, auth, storage });
  }
}

export interface InitFirebaseResult {
  fireStore: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  storage: firebase.storage.Storage;
}

export const initFireStore = new FireStoreInit().initFirebase();
