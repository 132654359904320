import React, { Component } from 'react';
import { localizedStrings } from '../../localizedStrings';
import isEqual from 'lodash/isEqual';
import PassChangeForm from './PassChangeForm';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';

interface State {
  passMain: string;
  passRetype: string;
  loading: boolean;
  valid: boolean;
  errors: { main: boolean; retype: boolean };
  disabled: boolean;
}
interface Props {
  rootStore?: RootStore;
}

@inject('rootStore')
@observer
class ChangeAdminPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      passMain: '',
      passRetype: '',
      loading: false,
      valid: true,
      errors: { main: false, retype: false },
      disabled: false,
    };
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ loading: true });
    if (this.checkValidity()) {
      // eslint-disable-next-line
      this.props.rootStore!.ChangeAdminPassword(this.state.passMain).finally(() => {
        this.setState({ loading: false });
        this.setState((prevState) => ({ ...prevState, errors: { main: false, retype: false } }));
      });
      this.handleCancel();
      return;
    }
    this.setState({ loading: false });
  };

  checkValidity = (): boolean => {
    const { passMain } = this.state;
    const matchedCase = [];
    matchedCase.push('[!"#¤%&/()=?¡@£$€¥{øæ~}`´*¨_.,µ¶§½]'); // Special Charector
    matchedCase.push('[A-ZÄÅÖ]'); // Uppercase Alpabates
    matchedCase.push('[0-9]'); // Numbers - optional
    matchedCase.push('[a-zäöå]'); // Lowercase Alphabates
    let ctr = 0;
    if (passMain.length > 7) {
      ctr++;
    }
    for (let i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(passMain)) {
        ctr++;
      }
    }
    if (passMain.trim() !== passMain) {
      // there exits whitespace in the string
      ctr = 0;
    }

    if (ctr < 5) {
      this.setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, main: true } }));
      return false;
    }
    this.setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, main: false } }));
    return this.checkPassMatch();
  };

  checkPassMatch = (): boolean => {
    if (!isEqual(this.state.passMain, this.state.passRetype)) {
      this.setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, retype: true } }));
      return false;
    }
    this.setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, retype: false } }));
    return true;
  };

  onInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  handleCancel = (): void => {
    this.setState({ passMain: '', passRetype: '' });
  };

  render(): React.ReactNode {
    const { passMain, passRetype, loading, errors, disabled } = this.state;
    return (
      <PassChangeForm
        onInputChange={this.onInputChange}
        handleCancel={this.handleCancel}
        handleSubmit={this.handleSubmit}
        passMain={passMain}
        passRetype={passRetype}
        title={localizedStrings.settings.changeAdminPass}
        loading={loading}
        uniqueId="i_d_changeadminpass"
        errors={errors}
        disabled={disabled}
      />
    );
  }
}

export default ChangeAdminPassword;
