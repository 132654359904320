import * as React from 'react';
import { Publish } from './Publish';
import { AppModeSwitcher } from './AppModeSwitcher';
import { ProfileMenu } from './ProfileMenu';
import { RootStore } from '../../stores/RootStore';
import { observer, inject } from 'mobx-react';
import { SettingsLink } from './SettingsLink';
import { Switch, Route } from 'react-router-dom';
import { SignInButton } from '../Auth/SignInButton';

interface Props {
  rootStore?: RootStore;
}

@inject('rootStore')
@observer
export class UserNav extends React.Component<Props> {
  // eslint-disable-next-line
  render() {
    const { rootStore } = this.props;
    // eslint-disable-next-line
    const { userExist, userCanEdit, project } = rootStore!;
    return (
      <div className="btn-group align-self-center" role="group">
        {project && (
          <div className="d-flex flex-row">
            {userExist ? <AppModeSwitcher /> : null}
            {userCanEdit ? <Publish /> : null}
            {userCanEdit ? <SettingsLink /> : null}
            {userExist ? <ProfileMenu /> : null}
          </div>
        )}
        <Switch>
          <Route path={'/signin'} exact={true} />
          <Route path={'/'} component={SignInButton} />
        </Switch>
      </div>
    );
  }
}
