import * as React from 'react';
import './SideMenu.css';
import { ArticleMenuItem } from './Admin/ArticleMenuItem';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { SectionsStore } from '../../stores/SectionsStore';
import { observer, inject } from 'mobx-react';
import ListGroup from 'reactstrap/lib/ListGroup';
import { ThemeContext } from '../App/App';
import { ApplicationStyle } from '@eir/core';

interface Props {
  articlesStore?: ArticlesStore;
  sectionsStore?: SectionsStore;
  categoryId: string;
}

@inject('articlesStore', 'sectionsStore')
@observer
export class SideMenuPublishMode extends React.Component<Props> {
  // eslint-disable-next-line
  getSectionsByCategory() {
    const { sectionsStore, categoryId } = this.props;
    // eslint-disable-next-line
    return sectionsStore!.sectionsByCategoryId(categoryId);
  }

  // eslint-disable-next-line
  render() {
    const { articlesStore, categoryId } = this.props;
    // eslint-disable-next-line
    const articlesWithNoSections = articlesStore!.articlesBy({ sectionId: null, categoryId: categoryId });
    return (
      <ThemeContext.Consumer>
        {
          // eslint-disable-next-line
          (appStyle: ApplicationStyle) => {
            return (
              <div className="section-wrapper">
                {this.getSectionsByCategory().map((section) => (
                  // eslint-disable-next-line
                  <div className="section py-1" key={section.id!}>
                    <div className="section-header px-3 py-2">
                      <h6 className="mb-0" style={{ fontWeight: 'bolder', color: appStyle.primaryDark }}>
                        {section.data().name}
                      </h6>
                    </div>
                    <ListGroup className="mb-2">
                      {
                        // eslint-disable-next-line
                        articlesStore!.articlesBy({ sectionId: section.id!, categoryId: categoryId }).map((a) => (
                          // eslint-disable-next-line
                          <ArticleMenuItem key={a.id!} article={a} />
                        ))
                      }
                    </ListGroup>
                  </div>
                ))}
                <div className="section-header p-2" />
                {articlesWithNoSections &&
                  // eslint-disable-next-line
                  articlesWithNoSections.map((a) => <ArticleMenuItem key={a.id!} article={a} />)}
              </div>
            );
          }
        }
      </ThemeContext.Consumer>
    );
  }
}
