import { ApplicationStyle, Article } from '@eir/core';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { RootStore } from '../../../stores/RootStore';
import { ThemeContext } from '../../App/App';

interface Props {
  rootStore?: RootStore;
  articlesStore?: ArticlesStore;
  article: Article;
}

@inject('rootStore', 'articlesStore')
@observer
export class ArticleMenuItem extends React.Component<Props> {
  render(): React.ReactNode {
    const { rootStore, articlesStore, article } = this.props;
    // eslint-disable-next-line
    const isActive = articlesStore!.activeArticle && articlesStore!.activeArticle === article.id;
    return (
      <ThemeContext.Consumer>
        {(appStyle: ApplicationStyle): React.ReactNode => {
          return (
            <ListGroupItem
              data-section={article.data().section}
              data-category={article.data().category}
              style={{ backgroundColor: isActive ? appStyle.primaryLight : '' }}
            >
              <Link
                to={
                  // eslint-disable-next-line
                  `/${rootStore!.projectId}/category/${article.data().category}/${article.id}`
                }
              >
                <span className="text-dark-gray">{article.data().name} </span>
              </Link>
            </ListGroupItem>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}
