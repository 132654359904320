/**
 * project navigation
 * @param { string } to - link path
 * @param { string } title - link title
 * ---
 * @param to must be on of the tow cases
 * 1- for internal categories: is must be the category id
 * 2- for external links: 'folder' name which will be included '/public/{project_id}/folder'
 * ---
 * in error case in @param to (when no actual associated category found)
 * the link will be rendered as external link
 *
 */

export default [
  { to: 'lakemedel', title: 'Läkemedel' },
  { to: 'external_content_demo', title: 'External Content' },
  { to: 'showroom', title: 'Showroom' },
  { to: 'ambulans', title: 'Ambulans' },
  { to: 'strama', title: 'Strama' },
  { to: 'test_html', title: 'Calculator' },
  { to: 'changelog', title: 'ChangeLog' },
];
