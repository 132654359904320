import * as React from 'react';
import { Article, Section } from '@eir/core';
import { Draggable, NotDraggingStyle, DraggingStyle } from 'react-beautiful-dnd';
import { appColors } from '../../App/App';
import { ArticleActions } from './ArticleActions';

interface Props {
  section: Section | null;
  categoryId: string;
  article: Article;
  index: number;
  key: string;
}

// eslint-disable-next-line
const sortingItemStyleArticle = (isDragging: boolean, draggableStyle: NotDraggingStyle | DraggingStyle | any) => ({
  margin: `0`,
  background: isDragging ? '#fff' : '',
  border: isDragging ? `1px dashed ${appColors.primary}` : '',
  ...draggableStyle,
});

export const DragableArticle: React.StatelessComponent<Props> = (props: Props) => {
  const { article, index, categoryId } = props;

  return (
    <Draggable
      draggableId={
        // eslint-disable-next-line
        article.id!
      }
      index={index}
    >
      {
        // eslint-disable-next-line
        (provided, snapshot) => (
          // eslint-disable-next-line
          // @ts-ignore
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={sortingItemStyleArticle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <ArticleActions article={article} categoryId={categoryId} />
          </div>
        )
      }
    </Draggable>
  );
};
