import { inject } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import { checkHyperLinkType, LinkTypes } from './LinkTypeChecker/LinkTypeChecker';
import { localizedStrings } from 'localizedStrings';

interface Props extends RouteComponentProps<{ projectId: string }> {
  rootStore?: RootStore;
  content: string;
  status: boolean;
}

const setupArticleCaller =
  '  (function() {\n' +
  '  if (typeof setupArticle === "function") {\n' +
  '     setupArticle();\n' +
  '  }\n' +
  '  })(); ';

@inject('rootStore')
class FrameText extends React.Component<Props> {
  iframe: HTMLIFrameElement;

  componentDidMount(): void {
    this.updateFrameContent();
    this.attachListenersOnIframe();
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.content !== this.props.content) {
      this.updateFrameContent();
    }
    this.attachListenersOnIframe();
    this.scrollToAnchorOnLoad();
  }

  componentWillUnmount(): void {
    // eslint-disable-next-line
    this.iframe.contentWindow!.document.removeEventListener('click', this.linkInterceptor);
  }

  attachListenersOnIframe(): void {
    const myframe: HTMLIFrameElement = document.getElementById('iprintable') as HTMLIFrameElement;
    if (myframe) {
      // eslint-disable-next-line
      myframe.contentDocument!.addEventListener('click', this.linkInterceptor, false);
    } else {
      this.iframe.onload = (): void => {
        // eslint-disable-next-line
        const iFrameDocument = this.iframe.contentWindow!.document;
        iFrameDocument.addEventListener('click', this.linkInterceptor, false);
      };
    }
  }

  updateFrameContent(): void {
    const frameContentWindow = this.iframe.contentWindow;
    const contentDocument = this.iframe.contentDocument;
    if (!frameContentWindow || !contentDocument) {
      return;
    }
    const frameDocument = frameContentWindow.document;
    frameDocument.open();
    frameDocument.write(this.props.content);
    frameDocument.close();

    // NOTE: The edited content with Froala requires the class to be applied
    contentDocument.body.classList.add('fr-view');
    this.updateHead();
  }

  render(): React.ReactNode {
    const { status } = this.props;
    return (
      <iframe
        title="article"
        id="iprintable"
        // eslint-disable-next-line
        ref={(ref) => (this.iframe = ref!)}
        style={!status ? { display: 'none' } : {}}
      />
    );
  }

  private updateHead = (): void => {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (!project) {
      return;
    }

    // dynamically add css and js files to iframe header
    const { projectCSS, projectJS, platformCSS, platformJS, froalaCSS } = project.data();
    const stylesheets = [projectCSS, platformCSS, froalaCSS];
    const scripts = [projectJS, platformJS];

    // eslint-disable-next-line
    const head = this.iframe.contentDocument!.head;
    if (!head) {
      return;
    }

    const base = document.createElement('base');
    base.target = '_top';
    head.appendChild(base);

    stylesheets.filter((s) => s).forEach((s) => head.appendChild(this.appendCss(s)));
    scripts.filter((s) => s).forEach((s) => head.appendChild(this.appendScript(s)));

    const customJS = document.createElement('script');
    customJS.type = 'text/javascript';
    customJS.text = setupArticleCaller;

    head.appendChild(customJS);
  };

  private appendCss(target: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = target;
    return link;
  }

  private appendScript(target: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = target;
    return script;
  }

  private linkInterceptor = (event: Event): void => {
    event.preventDefault();
    // eslint-disable-next-line
    const pid = this.props.rootStore!.projectId;
    const target = event.target as HTMLAnchorElement;
    const link = this.isHyperlink(target) ? target : this.closestATag(target);
    const { match, history } = this.props;

    if (!link) {
      return;
    }

    const theURL = new URL(link.href);
    // eslint-disable-next-line
    const hyperLinkType = checkHyperLinkType(theURL, pid!);

    if (hyperLinkType === LinkTypes.internalArticle) {
      // if there was no has in the URL it is article link
      try {
        const article = theURL.pathname.split('/').pop();
        const routeTo = `/${match.params.projectId}/articles/${article}`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`${localizedStrings.article.notFoundWarning} ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.internalChiefArticle) {
      // if there was no has in the URL it is article link
      try {
        const article = theURL.pathname.split('/').pop();
        const routeTo = `/${match.params.projectId}/chief-articles/${article}`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`${localizedStrings.article.notFoundWarning} ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.internalChangelogArticle) {
      // if there was no has in the URL it is article link
      try {
        const article = theURL.pathname.split('/').pop();
        const routeTo = `/${match.params.projectId}/changelog-articles/${article}`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`${localizedStrings.article.notFoundWarning} ${link}`);
        throw error;
      }
      return;
    }
    if (link.hash.startsWith('#')) {
      event.preventDefault();
      const targetId = link.hash.replace('#', '');
      this.scrollToAnchorLink(targetId);
      return;
    }

    if (hyperLinkType === LinkTypes.internalCategory) {
      try {
        const categoryId = theURL.pathname.split('/').pop();
        const routeTo = `/${match.params.projectId}/categories/${categoryId}`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`${localizedStrings.category.notFoundWarning} ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.internalChiefCategory) {
      try {
        const categoryId = theURL.pathname.split('/').pop();
        const routeTo = `/${match.params.projectId}/chief-categories/${categoryId}`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`${localizedStrings.category.notFoundWarning} ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.internalAbout) {
      try {
        const routeTo = `/${match.params.projectId}`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`Couldn't navigate to internal article! ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.internalPolicy) {
      try {
        const routeTo = `/${match.params.projectId}/privacy-policy`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`Couldn't navigate to internal article! ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.internalTerms) {
      try {
        const routeTo = `/${match.params.projectId}/term-of-use`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`Couldn't navigate to internal article! ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.internalExternal) {
      try {
        const categoryId = theURL.pathname.split('/').pop();
        const routeTo = `/${match.params.projectId}/external/${categoryId}`;
        history.push(routeTo);
        history.replace({ hash: theURL.hash });
      } catch (error) {
        console.error(`Couldn't navigate to internal article! ${link}`);
        throw error;
      }
      return;
    }
    if (hyperLinkType === LinkTypes.external) {
      try {
        window.open(link.href);
      } catch (error) {
        console.error(`Couldn't navigate to the link! ${link}`);
        throw error;
      }
    }
  };

  private closestATag(element: Element): HTMLAnchorElement | null {
    let el: Element | null = element;
    do {
      if (this.isHyperlink(el)) {
        // eslint-disable-next-line
        return el as any;
      }
      el = el.parentElement;
    } while (el !== null && el.nodeType === 1);
    return null;
  }
  private isHyperlink(target: Element): boolean {
    return target.tagName.toLowerCase() === 'a';
  }

  private scrollToAnchorLink(targetId: string): void {
    // eslint-disable-next-line
    const contentDocument = this.iframe.contentDocument!;
    const element = contentDocument.getElementById(targetId);
    if (element) {
      const { offsetTop } = element;
      contentDocument.body.scrollTop = offsetTop - 10;
    }
  }

  private scrollToAnchorOnLoad(): void {
    const {
      location: { hash },
    } = this.props;
    if (hash) {
      const targetId = hash.replace('#', '');
      this.scrollToAnchorLink(targetId);
    }
  }
}

export default withRouter(FrameText);
