import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import ArticleContent from './ArticleContent';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

type PathParamsType = {
  categoryId: string;
  articleId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  articlesStore?: ArticlesStore;
};

@inject('articlesStore')
@observer
class ArticleMainComponent extends React.Component<Props> {
  componentDidMount(): void {
    // eslint-disable-next-line
    this.props.articlesStore!.setCurrentArticle(this.props.match!.params.articleId);
  }

  componentDidUpdate(prevProps: Props): void {
    // eslint-disable-next-line
    const { categoryId, articleId } = this.props.match!.params;
    // eslint-disable-next-line
    const { categoryId: prevCategoryId, articleId: prevArticleId } = prevProps.match!.params;
    if (categoryId !== prevCategoryId || articleId !== prevArticleId) {
      // eslint-disable-next-line
      this.props.articlesStore!.setCurrentArticle(articleId);
    }
    if (
      articleId &&
      categoryId &&
      categoryId === prevCategoryId &&
      articleId === prevArticleId &&
      // eslint-disable-next-line
      this.props.articlesStore!.currentArticle === ''
    ) {
      // eslint-disable-next-line
      this.props.articlesStore!.setCurrentArticle(articleId);
    }
  }

  render(): React.ReactNode {
    const { match, location, articlesStore } = this.props;
    return (
      <ArticleContent
        // eslint-disable-next-line
        articleId={match!.params.articleId}
        // eslint-disable-next-line
        hash={location!.hash}
        // eslint-disable-next-line
        content={articlesStore!.currentArticle}
      />
    );
  }
}

export const ArticleMain = withRouter(ArticleMainComponent);
