import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { SearchResult } from './SearchResult';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { localizedStrings } from '../../localizedStrings';

interface Props {
  searchText: string;
  articlesStore?: ArticlesStore;
}

@inject('articlesStore')
@observer
export class SearchResults extends React.Component<Props> {
  componentDidMount(): void {
    // eslint-disable-next-line
    this.props.articlesStore!.setSearch(this.props.searchText);
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.searchText !== this.props.searchText) {
      // eslint-disable-next-line
      this.props.articlesStore!.setSearch(this.props.searchText);
    }
  }

  render(): React.ReactNode {
    const { articlesStore, searchText } = this.props;
    return (
      <ListGroup>
        <ListGroupItem>
          <div className="section">
            <h6>{localizedStrings.global.searchResult}</h6>
          </div>
        </ListGroupItem>
        {
          // eslint-disable-next-line
          articlesStore!.getSearchResult.map((article) => (
            // eslint-disable-next-line
            <SearchResult
              // eslint-disable-next-line
              key={article.id!}
              // eslint-disable-next-line
              articleId={article.id!}
              name={article.data().name}
              searchText={searchText}
              articleObject={article}
            />
          ))
        }
      </ListGroup>
    );
  }
}
