import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { UIStore } from '../../stores/UIStore';
import SortingForm from './Admin/SortingForm';
import './SideMenu.css';
import { SideMenuPublishMode } from './SideMenuPublishMode';
import SideMenuEditMode from './SideMenuEditMode';

interface Props {
  rootStore?: RootStore;
  categoryId: string;
  uiStore?: UIStore;
}

@inject('rootStore', 'uiStore')
@observer
export class SideMenu extends React.Component<Props> {
  // eslint-disable-next-line
  render() {
    const { rootStore, categoryId } = this.props;

    // eslint-disable-next-line
    if (!rootStore!.userCanEdit) {
      return <SideMenuPublishMode categoryId={categoryId} />;
    }

    return (
      <React.Fragment>
        <SortingForm categoryId={categoryId} />
        <SideMenuEditMode categoryId={categoryId} />
      </React.Fragment>
    );
  }
}
/**
 * @todo prepare for migrating to MOBX 5
 * export const SideMenu = inject('rootStore', 'uiStore')(observer(SideMenuComponent));
 */
