import * as React from 'react';
import { localizedStrings } from '../../../localizedStrings';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';

interface Props {
  opened: boolean;
  onYes: () => void;
  onCancel: () => void;
  header: string;
  // eslint-disable-next-line
  body: any;
  actionButton: string;
}

export const ModalConfirm: React.StatelessComponent<Props> = (props: Props) => (
  <Modal isOpen={props.opened}>
    <ModalHeader>{props.header}</ModalHeader>
    <ModalBody>{props.body}</ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={props.onYes}>
        {props.actionButton}
      </Button>
      <Button color="secondary" onClick={props.onCancel}>
        {localizedStrings.global.cancel}
      </Button>
    </ModalFooter>
  </Modal>
);
