import * as React from 'react';
import { SectionsStore } from '../../../stores/SectionsStore';
import { Section } from '@eir/core';
import { SectionActions } from './SectionActions';

interface Props {
  section: Section;
  sectionsStore: SectionsStore;
  categoryId: string;
}

export const SectionMenuItem: React.StatelessComponent<Props> = (props) => (
  // eslint-disable-next-line
  <SectionActions section={props.section} sectionsStore={props.sectionsStore} categoryId={props.categoryId!} />
);
