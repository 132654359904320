import { Configuration, ConfigurationData, LegalDocuments, Project, StoreService } from '@eir/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { action, computed, observable, runInAction } from 'mobx';
import { CustomNav } from '../components/TopMenu/TopMenu';
import { localizedStrings } from '../localizedStrings';
import { appCustomNavigation } from '../navigation';
import { toasts } from '../shared';
import { Image } from '@eir/core';

export type userType = 'GLOBAL_ADMIN' | 'PROJECT_ADMIN' | 'PROJECT_STAFF' | 'ILLEGAL_USER';
export class RootStore {
  @observable
  projectId: string | null = null;
  @observable
  storeService: StoreService;
  @observable
  private auth: firebase.auth.Auth;
  @observable
  private storage: firebase.storage.Storage;

  /**
   * Why not the whole configuration entity?
   * Mobx does not detect the change when the property is a function e.g `entity.data()`
   * thus, we store the configuration we need to update in a single separate observable
   */
  @observable
  configurationFeedbackEmails: [string];
  @observable
  configurationAboutInfo: string;

  @observable
  currentProject: Project;

  @observable
  private user: firebase.User | null;

  @observable
  loadingActivity: string = localizedStrings.global.startApplication;

  @observable
  legalDocuments: LegalDocuments;

  @observable
  customNavigation: CustomNav[] = [];

  @observable
  inDraftMode = false;

  @observable
  staffAccount = '';

  @observable
  staffPassword = '';

  constructor(
    storeService: StoreService,
    storage: firebase.storage.Storage,
    firebaseAuth: firebase.auth.Auth,
    projectId: string,
    inDraftMode: boolean,
  ) {
    this.auth = firebaseAuth;
    this.storeService = storeService;
    this.projectId = projectId;
    this.storage = storage;
    this.inDraftMode = inDraftMode;

    this.setupAuth();
    this.loadProject();
  }
  // actions

  // eslint-disable-next-line
  @action
  setCurrentUser(user: firebase.User) {
    this.user = user;
  }

  // eslint-disable-next-line
  @action
  loadProject() {
    return this.storeService
      .fetchProject()
      .then((result: Project) => {
        this.currentProject = result;
        this.loadProjectConfiguration();
        this.loadProjectConfigurationOnUpdate();
        this.loadStaticText();
        // eslint-disable-next-line
        this.loadCustomNavigation(result.id!);
        // eslint-disable-next-line
        this.loadFavIcon(result.id!);
        this.getProjectStaffAccount();
        return result;
      })
      .catch(() => (this.loadingActivity = localizedStrings.project.notLoaded));
  }

  // eslint-disable-next-line
  loadFavIcon = (_id: string) => {
    const oldLink: HTMLLinkElement | null =
      document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
    const newLink1 = document.createElement('link');
    const newLink2 = document.createElement('link');

    newLink1.rel = 'icon';
    newLink2.rel = 'shortcut icon';
    newLink1.type = 'image/x-icon';
    newLink2.type = 'image/x-icon';
    newLink1.href = `/assets/images/${_id}/favicon.ico`;
    newLink2.href = `/assets/images/${_id}/favicon.ico`;

    if (oldLink) {
      // eslint-disable-next-line
      document.head!.removeChild(oldLink);
    }
    // eslint-disable-next-line
    document.head!.appendChild(newLink1);
    // eslint-disable-next-line
    document.head!.appendChild(newLink2);
  };

  /**
   * configuration should be loaded/reloaded using this sinlge method
   */
  @action
  async loadProjectConfiguration(): Promise<void> {
    await this.storeService.fetchProjectConfigurationDoc(this.inDraftMode).then((result: Configuration) => {
      const { feedbackEmails, aboutInfo } = result.data();
      this.configurationFeedbackEmails = feedbackEmails;
      this.configurationAboutInfo = aboutInfo;
    });
  }

  @action
  async loadImages(): Promise<Image[]> {
    return this.storeService.fetchAllImages();
  }

  loadProjectConfigurationOnUpdate(): void {
    this.project.ref.onSnapshot((c) => {
      const data = c.data();
      // eslint-disable-next-line
      this.currentProject.data = () => ({ ...data } as any);
    });
  }

  @computed
  get getConfigurationFeedbackEmails(): [string] {
    return this.configurationFeedbackEmails;
  }

  async setupAuth(): Promise<void> {
    this.auth.onAuthStateChanged((user: firebase.User) => this.setCurrentUser(user));
  }

  @computed
  get userExist(): boolean {
    return this.user !== null;
  }

  @computed
  get currentUser(): firebase.User | null {
    return this.user;
  }

  @action
  public async isUserLegalInProject(): Promise<userType> {
    try {
      // eslint-disable-next-line
      const idTokenResult = await this.user!.getIdTokenResult();
      /**  the UID of user which is `-` seperated.
       * The ids:`['reviewer-account', 'admin-account']` are global accounts with all permissions on all projects.
       *  The staff accounts are represented as `staff-<projectName>`
       */
      const subId: string = idTokenResult.claims.sub;
      const splitedSubId = subId.split('-');
      const memberOf = idTokenResult.claims.memberOf;

      if (['reviewer-account', 'admin-account'].includes(subId)) {
        return 'GLOBAL_ADMIN';
      }
      if (splitedSubId[0] === 'staff' && splitedSubId[1] === this.currentProject.id) {
        return 'PROJECT_STAFF';
      }
      if (memberOf.length > 0 && memberOf.includes(this.currentProject.id)) {
        return 'PROJECT_ADMIN';
      }
      return 'ILLEGAL_USER';
    } catch (e) {
      return 'ILLEGAL_USER';
    }
  }

  @computed
  get userCanEdit(): boolean {
    return this.userExist && this.inDraftMode;
  }

  @computed
  public get project(): Project {
    return this.currentProject;
  }

  @action
  updateProjectConfiguration(config: Partial<ConfigurationData>): void {
    this.storeService
      .updateProjectConfiguration(config)
      .then(() => toasts.success(localizedStrings.settings.updateOk))
      .catch((error) => {
        toasts.error(error.message);
        const emails = this.configurationFeedbackEmails;
        emails.shift(); // remove the unshifted email at "addConfigurationFeedbackEmail"
      });
  }

  @action
  loadStaticText(): void {
    this.storeService.fetchLegalDocuments().then((documents) => (this.legalDocuments = documents));
  }

  @action
  loadCustomNavigation(projectId: string): void {
    this.customNavigation = appCustomNavigation[projectId].default;
  }

  @computed
  get appStorage(): firebase.storage.Storage {
    return this.storage;
  }

  @computed
  get storagePath(): string {
    return `public/${this.projectId}/assets/media/images/`;
  }

  addConfigurationFeedbackEmail(email: string): void {
    const emails = this.configurationFeedbackEmails;
    // FIXME: Check if the email can be added then insert it to the array, glue fix with "emails.shift()"
    emails.unshift(email);
    this.updateProjectConfiguration({ feedbackEmails: emails });
  }

  deleteConfigurationFeedbackEmail(email: string): void {
    const emails = this.configurationFeedbackEmails;
    emails.splice(emails.indexOf(email), 1);
    this.updateProjectConfiguration({ feedbackEmails: emails });
  }

  updateConfigurationAboutInfo(newContent: string): void {
    this.updateProjectConfiguration({ aboutInfo: newContent });
  }

  async signIn(email: string, password: string): Promise<void> {
    localStorage.removeItem('APP_MODE');
    await this.auth.signInWithEmailAndPassword(email, password);
  }

  @computed
  get getStaffAccount(): string {
    return this.staffAccount;
  }

  @action
  async getProjectStaffAccount(): Promise<void> {
    const doc = await this.storeService.fetchProjectStaffAccount();
    runInAction(() => {
      if (doc !== null) {
        this.staffAccount = doc.get('value');
      }
    });
  }

  @action
  async getProjectStaffPassword(): Promise<void> {
    const doc = await this.storeService.fetchProjectStaffPassword();
    runInAction(() => {
      if (doc !== null) {
        this.staffPassword = doc.get('value');
      }
    });
  }

  @action
  async ChangeStaffPassword(password: string): Promise<void> {
    this.storeService
      .changeStaffPassword(password)
      .then((result) => {
        toasts.success(localizedStrings.settings.passChangeSuccess);
      })
      .catch((error) => {
        toasts.error(`Server Error!. ${localizedStrings.settings.passChangeFail}`);
      });
  }

  async ChangeAdminPassword(password: string): Promise<void> {
    this.storeService
      .changeAdminPassword(password)
      .then((result) => {
        toasts.success(localizedStrings.settings.passChangeSuccess);
      })
      .catch((error) => {
        toasts.error(`Server Error!. ${localizedStrings.settings.passChangeFail}`);
      });
  }

  async signOut(): Promise<void> {
    localStorage.removeItem('APP_MODE');
    await this.auth.signOut();
  }
}
