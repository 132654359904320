import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import './Layout.css';
import { observer, inject } from 'mobx-react';
import { ArticlesStore } from '../../stores/ArticlesStore';

type PathParamsType = {
  categoryId: string;
  articleId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  // eslint-disable-next-line
  sideMenu: any;
  // eslint-disable-next-line
  content: any;
  articlesStore?: ArticlesStore;
};

@observer
@inject('articlesStore')
export class BodyLayoutComponent extends React.Component<Props> {
  containerDiv: HTMLElement;

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.match.params.categoryId !== this.props.match.params.categoryId) {
      // eslint-disable-next-line
      this.props.articlesStore!.setCurrentArticle('');
      this.containerDiv.scrollTop = 0;
    }
  }

  render(): React.ReactNode {
    const { sideMenu, content } = this.props;
    return (
      <div className="body-layout">
        <nav
          ref={
            // eslint-disable-next-line
            (ref) => (this.containerDiv = ref!)
          }
          className="side-menu pr-0"
        >
          {sideMenu}
        </nav>
        <div className="body-content pl-0 bg-white shadow-sm">{content}</div>
      </div>
    );
  }
}

export const BodyLayout = withRouter(BodyLayoutComponent);
