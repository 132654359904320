import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { App } from './components/App/App';
import { LandingPage } from './components/LandingPage/LandingPage';

export const AppRoutes: React.StatelessComponent = () => (
  <Router>
    <React.Fragment>
      <Route path="/:projectId" component={App} />
      <Route path="/" component={LandingPage} exact={true} />
    </React.Fragment>
  </Router>
);
