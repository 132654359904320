import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from 'reactstrap/lib/Button';
import { localizedStrings } from '../../localizedStrings';
import { RootStore } from '../../stores/RootStore';
import { AppIcon } from '../icons/Icon';

type PathParamsType = {
  projectId: string;
};
type Props = RouteComponentProps<PathParamsType> & {
  rootStore?: RootStore;
};
interface State {
  dropdownOpen: boolean;
}

@inject('rootStore')
@observer
class ProfileMenuComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  // eslint-disable-next-line
  toggleDropMenu = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  // eslint-disable-next-line
  signOut = () => {
    // eslint-disable-next-line
    this.props.rootStore!.signOut().then(() => window.location.replace(`/${this.props.rootStore!.projectId}`));
  };

  // eslint-disable-next-line
  render() {
    const { rootStore } = this.props;
    // eslint-disable-next-line
    const currentUser = rootStore!.currentUser ? rootStore!.currentUser!.displayName : '';
    /* const content = rootStore!.userCanEdit ? `${localizedStrings.auth.logout}` : ''; */
    return (
      <Button onClick={this.signOut}>
        <AppIcon
          icon="out"
          content={localizedStrings.auth.logout}
          title={
            // eslint-disable-next-line
            currentUser!
          }
        />
      </Button>
    );
  }
}

export const ProfileMenu = withRouter(ProfileMenuComponent);
