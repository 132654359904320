import { Section, StoreService } from '@eir/core';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import { sortBy } from 'lodash';
import { localizedStrings } from '../localizedStrings';
import { toasts } from '../shared';

export class SectionsStore {
  @observable
  storeService: StoreService;

  @observable
  sortedSections: Section[] = [];

  @observable
  allSections: Section[] = [];

  @observable
  chiefSectionList: Section[] = [];

  @observable
  currentCategorySections: Section[] = [];

  sortingAfterModify: boolean;

  constructor(storeService: StoreService) {
    this.storeService = storeService;
    this.loadAllSections();
  }

  // eslint-disable-next-line
  @action.bound
  async loadAllSections() {
    const defaultSections = await this.storeService.fetchAllSections();
    const chiefSections = await this.loadChiefSections();
    const changelogSections = await this.loadChangelogSections();

    runInAction(() => {
      this.allSections = defaultSections.concat(chiefSections, changelogSections);
    });
  }

  async loadChiefSections(): Promise<Section[]> {
    try {
      const chiefSections = await this.storeService.fetchChiefSections();
      return chiefSections;
    } catch (e) {
      return [];
    }
  }

  async loadChangelogSections(): Promise<Section[]> {
    try {
      const changeLogSections = await this.storeService.fetchChangelogSections();
      return changeLogSections;
    } catch (e) {
      return [];
    }
  }

  sectionsByCategoryId(categoryId: string): Section[] {
    return this.allSections.filter((s) => s.data().category === categoryId).filter((s) => !s.data().deleted);
  }

  // eslint-disable-next-line
  @action.bound
  resetSorting() {
    this.sortedSections = [];
    this.sortingAfterModify = false;
  }

  // eslint-disable-next-line
  @action.bound
  cancelSorting() {
    this.sortedSections = [];
    this.sortingAfterModify = false;
    this.loadAllSections();
  }

  /**
   * 'mobx' does not update when you have @computed function
   * none of these works : https://github.com/mobxjs/mobx/issues/291
   * - we use this to push any king of kind of change we want to have a reaction to.
   * How to use:
   * - push a new value ('Subject') to the array
   * - observer will `notify` with a 'Subject` use `sectionsCustomChanges.observe(()=>)`
   * @type {IObservableArray<string>}
   */
  // TODO: find a better solution
  sectionsCustomChanges = observable.box();

  // eslint-disable-next-line
  @action.bound
  addSectionSorting(newSectionsOrder: Section[], isChief: boolean, isChangelog: boolean) {
    // const isChangelog: boolean = newSectionsOrder[0].data().category === 'changelog';
    this.sortedSections = [];
    newSectionsOrder.forEach((s, i) => {
      const temp = s;
      const tempData = s.data();
      // eslint-disable-next-line
      temp.data = () => ({ ...tempData, orderIndex: i });
      newSectionsOrder[i] = temp;
      this.sortedSections.push(temp);
    }, newSectionsOrder);

    newSectionsOrder.forEach((s, i) => {
      const found = this.allSections.find((f) => f.id === s.id);
      if (found) {
        found.data().orderIndex = s.data().orderIndex;
      }
    });
    this.allSections = sortBy(this.allSections, (s) => s.data().orderIndex);
    // only after delete and add save sorting. on drag/drop user must verify saving
    if (this.sortingAfterModify) {
      this.saveSorting(isChief, isChangelog);
    }
  }

  // eslint-disable-next-line
  @action.bound
  saveSorting(isChief: boolean, isChangelog: boolean) {
    if (this.sortedSections.length > 0) {
      if (isChief) {
        this.storeService
          .updateChiefSectionsOrder(toJS(this.sortedSections))
          .then(() => {
            if (!this.sortingAfterModify) {
              toasts.success(localizedStrings.section.sortingSaved);
            }
            this.resetSorting();
          })
          .catch((error) => {
            toasts.error(error.message);
          });
      } else if (isChangelog) {
        this.storeService
          .updateChangelogSectionsOrder(toJS(this.sortedSections))
          .then(() => {
            if (!this.sortingAfterModify) {
              toasts.success(localizedStrings.section.sortingSaved);
            }
            this.resetSorting();
          })
          .catch((error) => {
            toasts.error(error.message);
          });
      } else {
        this.storeService
          .updateSectionsOrder(toJS(this.sortedSections))
          .then(() => {
            if (!this.sortingAfterModify) {
              toasts.success(localizedStrings.section.sortingSaved);
            }
            this.resetSorting();
          })
          .catch((error) => {
            toasts.error(error.message);
          });
      }
    }
  }

  // eslint-disable-next-line
  @computed
  get sortingButtonsEnabled() {
    return this.sortedSections.length > 0 && !this.sortingAfterModify;
  }

  // eslint-disable-next-line
  @action.bound
  async addSection(name: string, categoryId: string, isChief: boolean, isChangelog: boolean) {
    let result = null;

    try {
      if (isChief) {
        result = await this.storeService.addChiefSection(name, categoryId);
      } else if (isChangelog) {
        result = await this.storeService.addChangelogSection(name, categoryId);
      } else {
        result = await this.storeService.addSection(name, categoryId);
      }

      toasts.success(localizedStrings.section.addOk);
      this.allSections.unshift(result);
      this.sortingAfterModify = true;
      /**
       * @todo for unknown reason/somewhere MOBX caching the previous
       * state and after addition/deletion and after a second render
       * returning the previous state.therefore we make a temp from current state.
       * this problem should be investigated and this pattern should be optimized
       */
      const temp = this.allSections.filter((s) => s.data().category === categoryId && !s.data().deleted);
      this.addSectionSorting(temp, isChief, isChangelog);
    } catch (error) {
      toasts.error(error.message);
    }
  }

  // eslint-disable-next-line
  @action.bound
  async deleteSection(sectionId: string, categoryId: string, isChief: boolean, isChangelog: boolean) {
    try {
      if (isChief) {
        await this.storeService.deleteChiefSection(sectionId);
      } else if (isChangelog) {
        await this.storeService.deleteChangelogSection(sectionId);
      } else {
        await this.storeService.deleteSection(sectionId);
      }

      toasts.success(localizedStrings.section.deleteOk);
      const deletedSectionIndex = this.allSections.findIndex((s) => s.id === sectionId);
      this.allSections.splice(deletedSectionIndex, 1);
      this.sortingAfterModify = true;
      /**
       * @todo for unknown reason/somewhere MOBX caching the previous
       * state and after addition/deletion and after a second render
       * returning the previous state.therefore we make a temp from current state.
       * this problem should be investigated and this pattern should be optimized
       */
      const temp = this.allSections.filter((s) => s.data().category === categoryId && !s.data().deleted);
      this.addSectionSorting(temp, isChief, isChangelog);
    } catch (error) {
      toasts.error(error.message);
    }
  }

  // eslint-disable-next-line
  @action.bound
  updateSection(sectionId: string, newName: string, isChief: boolean, isChangelog: boolean) {
    if (isChief) {
      this.storeService
        .updateChiefSectionName(sectionId, newName)
        .then(() => {
          this.loadAllSections();
          toasts.success('Section updated!');
        })
        .catch((error) => toasts.error(error.message));
    } else if (isChangelog) {
      this.storeService
        .updateChangelogSection(sectionId, newName)
        .then(() => {
          this.loadAllSections();
          toasts.success('Section updated!');
        })
        .catch((error) => toasts.error(error.message));
    } else {
      this.storeService
        .updateSectionName(sectionId, newName)
        .then(() => {
          this.loadAllSections();
          toasts.success('Section updated!');
        })
        .catch((error) => toasts.error(error.message));
    }
  }
}
