import { ApplicationStyle } from '@eir/core';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route } from 'react-router';
import { Nav } from 'reactstrap';
import { RootStore } from '../../stores/RootStore';
import { ThemeContext } from '../App/App';
import { UserNav } from '../UserNav/UserNav';
import { AppLogo } from './AppLogo';
import './Header.css';
import { Search } from '../Search/Search';

interface Props {
  rootStore?: RootStore;
}

@inject('rootStore')
@observer
export class Header extends React.Component<Props> {
  render(): React.ReactNode {
    return (
      <ThemeContext.Consumer>
        {(appStyle: ApplicationStyle): React.ReactNode => {
          const style = { backgroundColor: appStyle.primary };
          return (
            <div className="d-flex bd-highlight" style={style}>
              <div className="mr-auto p-2 bd-highlight">
                <AppLogo />
              </div>
              <div className=" align-items-center p-2 bd-highlight align-self-center ">
                <Route render={({ history }): React.ReactNode => <Search history={history} />} />
              </div>
              <div className=" align-items-center p-2 bd-highlight align-self-center">
                <Nav>
                  <UserNav />
                </Nav>
              </div>
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}
