import * as React from 'react';
import { RootStore } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import './Header.css';

interface Props {
  rootStore?: RootStore;
}
interface State {
  showLogo: boolean;
}
@inject('rootStore')
@observer
export class AppLogo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showLogo: true,
    };
  }

  onLoadError = (): void => {
    this.setState({
      showLogo: false,
    });
  };

  render(): React.ReactNode {
    const { showLogo } = this.state;
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;

    return (
      <div className="app-title">
        <Link to={project ? `/${project.id}` : '/'}>
          {showLogo && project && (
            <img
              src={
                // eslint-disable-next-line
                `/assets/images/${project.id!}/logo.png`
              }
              alt="logo"
            />
          )}
          {project && <h3 className="mb-0 pl-3 text-white">{project.data().name}</h3>}
        </Link>
      </div>
    );
  }
}
