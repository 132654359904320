import * as React from 'react';
import { BodyLayout } from '../Layout/BodyLayout';
import { RouteComponentProps, withRouter } from 'react-router';
import { EmailSettings } from './EmailSettings';
import { SettingsSideMenu } from './SettingsSideMenu';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { localizedStrings } from '../../localizedStrings';
import { CategorySettings } from './CategorySettings';
import AccountMangement from './AccountMangement';

type PathParamsType = {
  projectId: string;
  settingsPage: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  rootStore?: RootStore;
};

@inject('rootStore')
@observer
class SettingsComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.checkConfigAuth();
  }

  componentDidMount(): void {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      document.title = `${project.data().name} | Settings`;
    }
  }
  componentDidUpdate(): void {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      document.title = `${project.data().name} | Settings`;
    }
  }

  checkConfigAuth = (): void => {
    const { rootStore } = this.props;
    // eslint-disable-next-line
    if (!rootStore!.userExist) {
      // eslint-disable-next-line
      this.props.history.push(`/${rootStore!.projectId}/signIn`);
    }
  };

  render(): React.ReactNode {
    const { settingsPage } = this.props.match.params;
    const side = <SettingsSideMenu />;
    // to be changed according to `settingsPage` url parameter
    let content;
    if (settingsPage === 'emails') {
      content = <EmailSettings />;
    }
    if (settingsPage === 'category') {
      content = <CategorySettings />;
    }

    if (settingsPage === 'accountmng') {
      content = <AccountMangement />;
    }

    // eslint-disable-next-line
    return this.props.rootStore!.configurationFeedbackEmails ? (
      <BodyLayout sideMenu={side} content={content} />
    ) : (
      <div> ... {localizedStrings.global.loading}</div>
    );
  }
}

export const Settings = withRouter(SettingsComponent);
