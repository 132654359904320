import React, { useState, useDebugValue } from 'react';
import { localizedStrings } from '../../localizedStrings';
import { AppIcon } from '../icons/Icon';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import PopoverBody from 'reactstrap/lib/PopoverBody';

interface Props {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCancel: () => void;
  passMain: string;
  passRetype: string;
  title: string;
  loading: boolean;
  uniqueId: string;
  errors: { main: boolean; retype: boolean };
  disabled: boolean;
}

const PassChangeForm: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [popoverStat, setPopoverStat] = useState(false);
  useDebugValue(popoverStat ? 'POPOVER TRUE' : 'POPOVERFALSE');
  if (props.disabled) {
    return null;
  }
  return (
    <div className="contianer">
      <div className="row">
        <form className="col-4" onSubmit={props.handleSubmit}>
          <p className="mt-3 mb-2">{props.title}</p>

          <div className="form-group">
            <div className="input-group mb-3">
              <input
                type={visible ? 'text' : 'password'}
                className={'form-control ' + (props.errors.main ? 'is-invalid' : '')}
                name="passMain"
                id={`passMain${props.title}`}
                placeholder="Password"
                value={props.passMain}
                onChange={(e): void => {
                  props.onInputChange(e);
                }}
              />

              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2" onClick={(): void => setVisible(!visible)}>
                  <AppIcon icon={visible ? 'eye' : 'eyeSlash'} color="blue" />
                </span>
              </div>
              {props.errors.main ? <div className="invalid-feedback">Password format is wrong!</div> : null}
            </div>
          </div>
          <div className="form-group">
            <div className="input-group mb-3">
              <input
                type={visible ? 'text' : 'password'}
                className={'form-control ' + (props.errors.retype ? 'is-invalid' : '')}
                name="passRetype"
                id={`passRetype${props.title}`}
                placeholder="Retype-Password"
                value={props.passRetype}
                onChange={(e): void => props.onInputChange(e)}
              />
              {props.errors.retype ? <div className="invalid-feedback">Passwords do not match!</div> : null}
            </div>
          </div>
          <div className="form-row align-items-center">
            <button type="submit" className="btn ml-1 mr-4">
              {props.loading ? (
                <AppIcon icon="spinner" title="Spara..." content="Spara..." />
              ) : (
                <AppIcon icon="check" color="blue" content={localizedStrings.global.save} />
              )}
            </button>
            <button type="button" className="btn ml-1 mr-4" onClick={props.handleCancel}>
              <AppIcon icon="cancel" color="red" content={localizedStrings.global.clear} />
            </button>
            <Button id={props.uniqueId} type="button" onClick={(): void => setPopoverStat(!popoverStat)}>
              <AppIcon icon="info" color="blue" />
            </Button>
            <Popover placement="right" isOpen={popoverStat} target={props.uniqueId}>
              <PopoverHeader>Lösenord mönster</PopoverHeader>
              <PopoverBody>
                <div>
                  <strong>Administratörskonto lösenord</strong>
                  <p className="p-0 m-0"> Minst en liten bokstav</p>
                  <p className="p-0 m-0"> Minst en stor bokstav</p>
                  <p className="p-0 m-0"> Minst ett specialtecken [?,!,$,...]</p>
                  <p className="p-0 m-0"> Minst ett nummer</p>
                  <p className="p-0 m-0"> Minst 8 tecken långt</p>
                  <p className="p-0 m-0"> Inget vitt utrymme</p>
                </div>
                <div>
                  <strong>Personalkonto lösenord</strong>
                  <p className="p-0 m-0"> Bara nummer</p>
                  <p className="p-0 m-0">Minsta 6 och högst 8 siffror långa</p>
                </div>{' '}
              </PopoverBody>
            </Popover>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PassChangeForm;
