import { Article } from '@eir/core';
import * as React from 'react';
import { FormEvent } from 'react';
import { Button } from 'reactstrap';
import { localizedStrings } from '../../../localizedStrings';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { AppIcon } from '../../icons/Icon';
import '../SideMenu.css';
import { inject, observer } from 'mobx-react';
import { toasts } from 'shared';
import { CategoriesStore } from 'stores/CategoriesStore';

interface Props {
  article: Article;
  articlesStore?: ArticlesStore;
  onHideEdit: () => void;
  categoryId: string;
  categoriesStore?: CategoriesStore;
}
interface State {
  inputText: string;
}

const MAX_ARTICLE_NAME_LENGTH = 120;

@inject('articlesStore', 'categoriesStore')
@observer
export class ArticleEditName extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputText: props.article.data().name,
    };
  }
  onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    // eslint-disable-next-line
    const isChief = this.props.categoriesStore!.isCategoryChief(this.props.categoryId) ? true : false;
    const isChangelog = this.props.categoryId === 'changelog';
    const { inputText } = this.state;
    const validName = inputText.trim() !== '' && inputText.length < MAX_ARTICLE_NAME_LENGTH;
    if (validName) {
      // eslint-disable-next-line
      this.props.articlesStore!.saveArticle(this.props.article.id!, isChief, isChangelog, null, inputText);
      this.hideEdit();
    } else {
      toasts.error(localizedStrings.article.wrongArticleName);
    }
  };

  hideEdit = (): void => {
    this.props.onHideEdit();
  };
  // eslint-disable-next-line
  onInputChange = (e: any): void => {
    // eslint-disable-next-line
    this.setState({ inputText: e!.target.value });
  };

  render(): React.ReactNode {
    const { inputText } = this.state;
    const isDisabled = !inputText || inputText === this.props.article.data().name;

    return (
      <form onSubmit={this.onSubmit} className=" d-flex justify-content-between align-items-center flex-grow-1">
        <input id="nameInput" onChange={this.onInputChange} value={this.state.inputText} className="col-sm-7" />
        <span className="d-flex default-cursor pr-2">
          <Button type="submit" disabled={isDisabled} outline={true}>
            <AppIcon icon="check" title={localizedStrings.global.save} />
          </Button>
          <Button onClick={this.hideEdit} outline={true}>
            <AppIcon icon="cancel" title={localizedStrings.global.cancel} />
          </Button>
        </span>
      </form>
    );
  }
}
