import * as React from 'react';
import { Link } from 'react-router-dom';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import { RootStore } from '../../stores/RootStore';
import { inject } from 'mobx-react';
import { Article } from '@eir/core';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { AppIcon } from '../icons/Icon';

interface Props {
  rootStore?: RootStore;
  categoriesStore?: CategoriesStore;
  articleId: string;
  name: string;
  searchText: string;
  articleObject: Article;
}

@inject('rootStore', 'categoriesStore')
export class SearchResult extends React.Component<Props> {
  render(): React.ReactNode {
    const { rootStore, articleId, name, searchText } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userExists = rootStore!.userExist;
    const articleCategory = this.props.articleObject.data().category;
    const isArticleLocked = (): boolean =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      !userExists && this.props.categoriesStore!.isCategoryProtected(articleCategory);

    return (
      <ListGroupItem className="list-group-item flex-grow-1">
        {isArticleLocked() ? (
          <del>
            <Link to={`#`}>
              <span className="text-dark-gray">
                <AppIcon icon="lock" content={name} />
              </span>
            </Link>
          </del>
        ) : (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <Link to={`/${rootStore!.projectId}/search/${searchText}/${articleId}`}>
            <span className="text-dark-gray"> {name} </span>
          </Link>
        )}
      </ListGroupItem>
    );
  }
}
