export enum LinkTypes {
  internalAbout, // Link to project Home/About page
  internalArticle, // Link to an article in the same project
  internalCategory, // Link to a category in the same project
  internalExternal, // Link to an external category in the same project (e.g. calculator)
  internalPolicy, // Link to PrivacyPolicy
  internalTerms, // Link to Term of use
  external, // Link to a URL outside of Eir platform
  internalChiefArticle, // link to an article in chief category
  internalChiefCategory,
  internalChangelogArticle,
}

export function checkLinkType(url: URL, pid: string): number {
  if (['localhost', 'softwerk', 'eir.softwerk.se'].some((el) => url.hostname.includes(el))) {
    // we are in the Eir platform
    const pathArray = url.pathname.split('/');
    const pidFromPath = pathArray[1];
    if (pid === pidFromPath) {
      // we are in the same project
      if (pathArray.length === 2) {
        // we are pointing to current project Home/About page
        return LinkTypes.internalAbout;
      }
      if (pathArray.includes('category')) {
        // we are pointing a category NOT external, category itself or an article inside that.
        if (pathArray.length === 4) {
          // we are pointing to category itself
          return LinkTypes.internalCategory;
        }
        // We are pointing an article within a category
        if (pathArray.length > 4) {
          return LinkTypes.internalArticle;
        }
      }
      if (pathArray.includes('external')) {
        // we are pointing an external category e.g. calculator
        return LinkTypes.internalExternal;
      }
      if (pathArray[pathArray.length - 1] === 'privacy-policy') {
        return LinkTypes.internalPolicy;
      }
      if (pathArray[pathArray.length - 1] === 'term-of-use') {
        return LinkTypes.internalTerms;
      }
    }
    // There is another condition that we are in the same project;
    // When editing one of the above cases
    if (pathArray[1] === 'about') {
      return LinkTypes.internalAbout;
    }
    if (pathArray[1] === 'articles') {
      return LinkTypes.internalArticle;
    }
    if (pathArray[1] === 'categories') {
      return LinkTypes.internalCategory;
    }
    if (pathArray[1] === 'privacy-policy') {
      return LinkTypes.internalPolicy;
    }
    if (pathArray[1] === 'term-of-use') {
      return LinkTypes.internalTerms;
    }
    if (pathArray[1] === 'externals') {
      return LinkTypes.internalExternal;
    }
    return LinkTypes.external;
  }
  return LinkTypes.external;
}

export function checkHyperLinkType(url: URL, pid: string): number {
  if (['localhost', 'softwerk', 'eir.softwerk.se'].some((el) => url.hostname.includes(el))) {
    // we are in the Eir platform
    const pathArray = url.pathname.split('/');
    if (pathArray[1] === 'about') {
      return LinkTypes.internalAbout;
    }
    if (pathArray[1] === 'articles') {
      return LinkTypes.internalArticle;
    }
    if (pathArray[1] === 'chief-articles') {
      return LinkTypes.internalChiefArticle;
    }
    if (pathArray[1] === 'changelog-articles') {
      return LinkTypes.internalChangelogArticle;
    }
    if (pathArray[1] === 'categories') {
      return LinkTypes.internalCategory;
    }
    if (pathArray[1] === 'chief-categories') {
      return LinkTypes.internalChiefCategory;
    }
    if (pathArray[1] === 'privacy-policy') {
      return LinkTypes.internalPolicy;
    }
    if (pathArray[1] === 'term-of-use') {
      return LinkTypes.internalTerms;
    }
    if (pathArray[1] === 'externals') {
      return LinkTypes.internalExternal;
    }
    // The hostname was Eir platform, but the path is not starting with any of above
    // So the link is toa nother Eir project which is considered as external
    return LinkTypes.external;
  }
  // none of above, obviusly external
  return LinkTypes.external;
}
