import { Category, PinProtectData, StoreService, ProtectCategoryData } from '@eir/core';
import { action, computed, observable, runInAction } from 'mobx';
import { localizedStrings } from '../localizedStrings';
import { toasts } from '../shared';
import { ChiefCategory } from '@eir/core/dist/models/features';

export class CategoriesStore {
  @observable
  storeService: StoreService;

  constructor(storeService: StoreService) {
    this.storeService = storeService;
    this.loadCategories();
    this.loadChiefCategoryList();
  }

  /** @TODO a complete re-structuring of Mobx. it has serious problems with observables
   * currently everything works with a bit of glue
   * @TODO investigate usage of  ObservableArray
   * @observable categories=new ObservableArray<Category>([],{});
   */

  @observable
  categories: Category[] = [];

  @observable
  chiefCategoryList: ChiefCategory[] = [];

  userAuthorizedCategories: string[] = [];

  // getters
  // @computed
  getAllCategories(): Category[] {
    return this.categories;
  }

  @computed
  get returnAllCetgories(): Category[] {
    return this.categories;
  }

  isCategoryChief(categoryId: string): ChiefCategory | undefined {
    return this.chiefCategoryList.find((c) => c.id === categoryId);
  }

  @computed
  get currentCategory(): Category | ChiefCategory {
    return this.selectedCategory != null ? this.selectedCategory : this.categories[0];
  }

  // eslint-disable-next-line
  @computed
  get hasCategories() {
    return this.categories.length > 0;
  }

  // eslint-disable-next-line
  @computed
  get hasChiefCategories() {
    return this.chiefCategoryList.length > 0;
  }

  @observable
  selectedCategory: Category | ChiefCategory;

  // actions
  // eslint-disable-next-line
  @action.bound
  setCurrentCategory(categoryId?: string) {
    if (categoryId) {
      const cat = this.categories.find((c) => c.id === categoryId);
      this.selectedCategory = cat !== null && cat !== undefined ? cat : this.categories[0];
    }
    const category = this.categories.find((c) => c.id === categoryId);
    if (category !== null && category !== undefined) {
      this.selectedCategory = category;
    }
  }

  // eslint-disable-next-line
  @action.bound
  private loadCategories() {
    this.getAllCategories();
    this.categories = [];
    this.storeService.fetchCategoryList().then((result) => {
      runInAction(() => {
        this.categories = result;
        this.setCurrentCategory();
      });
    });
  }

  // eslint-disable-next-line
  @action
  /** depricated */
  pinProtectCategory(data: PinProtectData) {
    this.storeService
      .pinProtectCategory({ categoryId: data.categoryId, pin: data.pin })
      .then(() => {
        toasts.success(localizedStrings.category.lockOk);
        // eslint-disable-next-line
        const index = this.categories.findIndex((c) => c!.id === data.categoryId);
        const cat = this.categories[index];
        const preData = cat.data();
        // eslint-disable-next-line
        runInAction(() => (this.categories[index].data = () => ({ ...preData, pin: data.pin })));
      })
      // eslint-disable-next-line
      .catch((error: any) => toasts.error(localizedStrings.category.lockFail + error.message));
  }

  // eslint-disable-next-line
  @action.bound
  protectCategory(data: ProtectCategoryData) {
    this.storeService
      .protectCategory({ categoryId: data.categoryId, status: data.status })
      .then(() => {
        toasts.success(localizedStrings.category.protectOk);
      })
      // eslint-disable-next-line
      .catch((error: any) => toasts.error(localizedStrings.category.protectFail + error.message));
  }

  // eslint-disable-next-line
  private categoryProtected(id: string) {
    const cat = this.categories.find((c) => c.id === id);
    if (cat) {
      // eslint-disable-next-line
      const pin = cat!.data().pin;
      return pin !== undefined && pin !== null && pin !== '';
    }
    return false;
  }

  // eslint-disable-next-line
  @action
  isCategoryProtected(id: string) {
    const cat = this.categories.find((c) => c.id === id);
    if (cat) {
      return cat.data().isProtected;
    }
    return false;
  }

  checkCategoryPin(id: string, pin: string): boolean {
    // eslint-disable-next-line
    const category = this.categories.find((cat) => cat.id! === id);
    if (category) {
      // eslint-disable-next-line
      return category!.data().pin === pin;
    }
    return false;
  }

  // eslint-disable-next-line
  categoryAuth(id: string) {
    return this.categoryProtected(id) ? this.userAuthorizedCategories.indexOf(id) !== -1 : true;
  }

  // eslint-disable-next-line
  addAuthCategory(id: string) {
    if (!this.userAuthorizedCategories.find((e) => e === id)) {
      this.userAuthorizedCategories.push(id);
    }
  }

  getCategoryById(categoryId: string): Category | ChiefCategory | undefined {
    const category = this.categories.find((cat) => cat.id === categoryId);
    return category ? category : this.chiefCategoryList.find((cat) => cat.id === categoryId);
  }

  // eslint-disable-next-line
  loadChiefCategoryList() {
    this.storeService.fetchChiefCategoryList().then((result) => {
      runInAction(() => {
        this.chiefCategoryList = result;
      });
    });
  }
}
