import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Section, Article, ArticleData } from '@eir/core';
import { SectionsStore } from '../../stores/SectionsStore';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { DragableSection } from './Admin/DragableSection';
import { SectionAdd } from './Admin/SectionAdd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CategoriesStore } from 'stores/CategoriesStore';

type PathParamsType = {
  projectId: string;
  categoryId: string;
  articleId: string;
};
type Props = RouteComponentProps<PathParamsType> & {
  categoryId: string;
  articlesStore?: ArticlesStore;
  sectionsStore?: SectionsStore;
  categoriesStore?: CategoriesStore;
};
interface State {
  sectionIndex: number;
}

/**
 * This component `sideMenuEditMode` is acting as the whole menu's `DragDropContext`.
 * Therefore we need to have all the sections and articles of a category in here
 * to implement the `onDragEnd`.
 * on mount we ask the stores to fill the related arrays.
 */
@inject('articlesStore', 'sectionsStore', 'categoriesStore')
@observer
class SideMenuEditMode extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sectionIndex: -1,
    };
  }

  /**
   * @onDragEnd
   */
  onDragEnd = (result: DropResult): void => {
    // eslint-disable-next-line
    const isChief = this.props.categoriesStore!.isCategoryChief(this.props.match.params.categoryId) ? true : false;
    const isChangelog: boolean = this.props.match.params.categoryId === 'changelog';
    const { categoryId, sectionsStore, articlesStore } = this.props;
    const { destination, source, type } = result;

    // eslint-disable-next-line
    const currentCategorySections = sectionsStore!.sectionsByCategoryId(categoryId);
    // eslint-disable-next-line
    const currentCategoryArticles = articlesStore!.articlesByCategoryId(categoryId);

    if (!destination) {
      // the dragged item has been dropped outside of the droppable area, so do nothing
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      // the dragged item has been dropped to its previous location, so do nothing
      return;
    }
    // when we drag and drop a section. section draggable handle is the whole title div
    if (type === 'sections') {
      // create sectons from prevous order
      const newSectionsOrder: Section[] = currentCategorySections;
      const [removed] = newSectionsOrder.splice(source.index, 1);
      newSectionsOrder.splice(destination.index, 0, removed);
      // eslint-disable-next-line
      sectionsStore!.addSectionSorting(newSectionsOrder, isChief, isChangelog);
      return;
    }
    // when we drag and drop an article
    if (type === 'articles') {
      // eslint-disable-next-line
      let dragedFrom: any = null;
      // eslint-disable-next-line
      let dropedTo: any = null;
      if (source.droppableId === 'ORPHANS_SECTION') {
        dragedFrom = null;
      } else {
        dragedFrom = currentCategorySections.find((s) => s.id === source.droppableId);
      }
      if (destination.droppableId === 'ORPHANS_SECTION') {
        dropedTo = null;
      } else {
        dropedTo = currentCategorySections.find((s) => s.id === destination.droppableId);
      }

      // if the article draged and dropped in the same section
      if (dragedFrom === dropedTo) {
        // indicates the new article order of the section which drag drop happened in
        const secWithNewArticOrder: Article[] =
          dragedFrom === null
            ? currentCategoryArticles.filter((a) => a.data().section === null)
            : currentCategoryArticles.filter(
                (a) => a.data().section === source.droppableId && a.data().section === destination.droppableId,
              );
        const [removed] = secWithNewArticOrder.splice(source.index, 1);
        secWithNewArticOrder.splice(destination.index, 0, removed);
        // eslint-disable-next-line
        articlesStore!.addArticleSortingSameSection(secWithNewArticOrder, isChief, isChangelog);
        return;
      }
      // if article dragged from a section and dropped into another section
      if (dragedFrom !== dropedTo) {
        // indicates the section which the article dragged from
        const firstSecWithNewArticOrder: Article[] =
          dragedFrom === null
            ? currentCategoryArticles.filter((a) => a.data().section === null)
            : currentCategoryArticles.filter((a) => a.data().section === source.droppableId);
        // remove the article from that section
        const [removed] = firstSecWithNewArticOrder.splice(source.index, 1);

        // indicates the section which the article dropped to
        const secondSecWithNewArticOrder: Article[] =
          dropedTo === null
            ? currentCategoryArticles.filter((a) => a.data().section === null)
            : currentCategoryArticles.filter((a) => a.data().section === destination.droppableId);
        const removedData = removed.data();
        removed.data = (): ArticleData => ({
          ...removedData,
          section: destination.droppableId === 'ORPHANS_SECTION' ? null : destination.droppableId,
        });
        secondSecWithNewArticOrder.splice(destination.index, 0, removed);
        // eslint-disable-next-line
        articlesStore!.addArticleSortingDiffSections(
          firstSecWithNewArticOrder,
          secondSecWithNewArticOrder,
          isChief,
          isChangelog,
        );
        return;
      }
    }
  };

  render(): React.ReactNode {
    let sectionIndex = -1;
    const { categoryId } = this.props;
    // eslint-disable-next-line
    const currectCategorySections = this.props.sectionsStore!.sectionsByCategoryId(categoryId);
    // eslint-disable-next-line
    const currentCategoryArticles = this.props.articlesStore!.articlesByCategoryId(categoryId);
    // eslint-disable-next-line
    const orphanArticles = this.props.articlesStore!.getOrphanArticles(categoryId);

    return (
      <div>
        <SectionAdd categoryId={categoryId} />
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="SIDE_MENU_EDIT_MODE" direction="vertical" type="sections">
            {
              // eslint-disable-next-line
              (provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  onScroll={(e): number => e.currentTarget.scrollTop}
                  className="articles-with-section"
                >
                  {
                    // eslint-disable-next-line
                    currectCategorySections!.map((section, index) => {
                      sectionIndex = index;
                      // eslint-disable-next-line
                      const currentSectionArticles: Article[] = currentCategoryArticles!.filter(
                        (article: Article) =>
                          article.data().section === section.id && article.data().category === categoryId,
                      );
                      return (
                        <DragableSection
                          // eslint-disable-next-line
                          key={section.id!}
                          section={section}
                          index={index}
                          articles={currentSectionArticles}
                          categoryId={categoryId}
                        />
                      );
                    })
                  }
                  <DragableSection
                    key="ORPHANS_SECTION"
                    section={null}
                    index={sectionIndex + 1}
                    articles={orphanArticles}
                    categoryId={categoryId}
                  />
                  {provided.placeholder}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
export default withRouter(SideMenuEditMode);
