import * as React from 'react';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { Article } from '@eir/core';
import { ModalConfirm } from './ModalConfirm';
import { localizedStrings } from '../../../localizedStrings';
import '../SideMenu.css';
import { AppIcon } from '../../icons/Icon';
import { inject } from 'mobx-react';
import { CategoriesStore } from 'stores/CategoriesStore';

interface Props {
  article: Article;
  articlesStore?: ArticlesStore;
  categoryId: string;
  categoriesStore?: CategoriesStore;
}

interface State {
  modalOpened: boolean;
}

@inject('articlesStore', 'categoriesStore')
export class ArticleDelete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpened: false,
    };
  }

  showModal = (): void => this.setState({ modalOpened: true });

  cancel = (): void => this.setState({ modalOpened: false });

  delete = (): void => {
    // eslint-disable-next-line
    const isChief: boolean = this.props.categoriesStore!.isCategoryChief(this.props.categoryId) ? true : false;
    // eslint-disable-next-line
    this.props.articlesStore!.deleteArticle(this.props.article, isChief);
    this.setState({ modalOpened: false });
  };

  render(): React.ReactNode {
    return (
      <div className="text-center default-cursor delete-button">
        <ModalConfirm
          opened={this.state.modalOpened}
          onYes={this.delete}
          onCancel={this.cancel}
          header={localizedStrings.article.deleteModalHeader}
          body={localizedStrings.article.deleteModalBody}
          actionButton={localizedStrings.global.delete}
        />

        <AppIcon icon="trash" title={localizedStrings.global.delete} onClick={this.showModal} />
      </div>
    );
  }
}
