import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { localizedStrings } from '../../localizedStrings';
import { ThemeContext } from '../App/App';
import { ApplicationStyle } from '@eir/core';

type PathParamsType = {
  projectId: string;
  settingsPage: string;
};

type Props = RouteComponentProps<PathParamsType> & {};

const SettingsSideMenuComponent: React.StatelessComponent<Props> = (props: Props) => {
  const { projectId, settingsPage } = props.match.params;
  return (
    <ThemeContext.Consumer>
      {(appStyle: ApplicationStyle): React.ReactNode => {
        return (
          <div className="list-group">
            <div
              className="list-group-item list-group-item-action"
              style={settingsPage === 'emails' ? { backgroundColor: appStyle.primaryLight } : {}}
            >
              <Link to={`/${projectId}/settings/emails`}>{localizedStrings.settings.emailSettings}</Link>
            </div>
            <div
              className="list-group-item list-group-item-action"
              style={settingsPage === 'category' ? { backgroundColor: appStyle.primaryLight } : {}}
            >
              <Link to={`/${projectId}/settings/category`}>{localizedStrings.settings.category}</Link>
            </div>
            <div
              className="list-group-item list-group-item-action"
              style={settingsPage === 'accountmng' ? { backgroundColor: appStyle.primaryLight } : {}}
            >
              <Link to={`/${projectId}/settings/accountmng`}>{localizedStrings.settings.accountmng}</Link>
            </div>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export const SettingsSideMenu = withRouter(SettingsSideMenuComponent);
