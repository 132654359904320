import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'reactstrap';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import { localizedStrings } from '../../../localizedStrings';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { SectionsStore } from '../../../stores/SectionsStore';
import { UIStore } from '../../../stores/UIStore';
import { AppIcon } from '../../icons/Icon';
import '../SideMenu.css';
import { CategoriesStore } from 'stores/CategoriesStore';

interface Props {
  sectionsStore?: SectionsStore;
  articlesStore?: ArticlesStore;
  uiStore?: UIStore;
  categoriesStore?: CategoriesStore;
  categoryId: string;
}

@inject('sectionsStore', 'articlesStore', 'uiStore', 'categoriesStore')
@observer
class SortingForm extends React.Component<Props> {
  cancelSorting = (): void => {
    // eslint-disable-next-line
    this.props.articlesStore!.cancelSorting();
    // eslint-disable-next-line
    this.props.sectionsStore!.cancelSorting();
  };

  saveSorting = (): void => {
    // eslint-disable-next-line
    const isChief: boolean = this.props.categoriesStore!.isCategoryChief(this.props.categoryId) ? true : false;
    const isChangelog: boolean = this.props.categoryId === 'changelog';
    // eslint-disable-next-line
    this.props.articlesStore!.saveSorting(isChief, isChangelog);
    // eslint-disable-next-line
    this.props.sectionsStore!.saveSorting(isChief, isChangelog);
  };

  render(): React.ReactNode {
    const { sectionsStore, articlesStore } = this.props;
    // eslint-disable-next-line
    const sortingActionsEnabled = articlesStore!.sortingButtonsEnabled || sectionsStore!.sortingButtonsEnabled;

    return (
      <div>
        {sortingActionsEnabled && (
          <div className="pt-2 pb-2 pl-2 sorting-form-tabs">
            <div className="alert alert-warning mr-2 shadow-sm  rounded" role="alert">
              {localizedStrings.sorting.sortingHasChanged}
            </div>
            <ButtonGroup>
              <Button onClick={this.saveSorting} className="px-0 shadow-sm  rounded">
                <AppIcon
                  icon="check"
                  content={localizedStrings.sorting.saveSorting}
                  title={localizedStrings.global.save}
                />
              </Button>

              <Button onClick={this.cancelSorting} className="px-0 shadow-sm  rounded">
                <AppIcon
                  icon="cancel"
                  content={localizedStrings.sorting.cancelSorting}
                  title={localizedStrings.global.cancel}
                />
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
    );
  }
}
export default SortingForm;
