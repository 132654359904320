import * as React from 'react';

import { Article, Section, ArticleData } from '@eir/core';

import { inject, observer } from 'mobx-react';
import Button from 'reactstrap/lib/Button';
import { localizedStrings } from '../../../localizedStrings';
import { AppIcon } from '../../icons/Icon';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { toasts } from 'shared';
import { CategoriesStore } from 'stores/CategoriesStore';

interface Props {
  section: Section | null;
  categoryId: string;
  articlesStore?: ArticlesStore;
  categoriesStore?: CategoriesStore;
}

interface State {
  inputText: string;
}

@inject('articlesStore', 'categoriesStore')
@observer
export class SectionAddArticle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputText: '',
    };
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const { categoryId, section, articlesStore } = this.props;
    // eslint-disable-next-line
    const isChief: boolean = this.props.categoriesStore!.isCategoryChief(categoryId) ? true : false;
    const isChangelog: boolean = categoryId === 'changelog';
    if (this.state.inputText.length > 120) {
      toasts.error(localizedStrings.article.wrongArticleName);
      return;
    }

    const articleData: ArticleData = {
      name: this.state.inputText,
      content: ` <p> En tom artikel som heter <span style="color:blue;font-weight:bold"> ${this.state.inputText} </span> </p>`,
      category: categoryId,
      orderIndex: -1,
      // eslint-disable-next-line
      section: section === null ? null : section!.id,
    };

    console.log('ADDING AN ARTICLE', articleData, isChief, isChangelog);

    // eslint-disable-next-line
    articlesStore!
      .addArticle(articleData, isChief, isChangelog)
      .then((a: Article) => this.handleAddArticle(a))
      .catch((error) => {
        toasts.error(error.message);
      });
    this.handleCancel();
  };

  handleAddArticle = (article: Article): void => {
    /**/
  };

  handleCancel = (): void => {
    this.setState({ inputText: '' });
  };

  // eslint-disable-next-line
  onInputChange = (e: any): void => {
    // eslint-disable-next-line
    this.setState({ inputText: e!.target.value });
  };

  render(): React.ReactNode {
    const { inputText } = this.state;
    const isDisbaled = inputText.length <= 0 || inputText.trim() === '' || inputText.trim().length > 160;
    return (
      <form
        onSubmit={this.handleSubmit}
        className="d-flex align-items-center justify-content-between align-items-center p-1 ml-3"
      >
        <input
          id="articleTitle"
          placeholder={localizedStrings.article.addNewArticle}
          value={this.state.inputText}
          onChange={this.onInputChange}
          type="articleName"
          className="col-sm-8"
          maxLength={120}
        />
        {!isDisbaled ? (
          <span className="d-flex default-cursor pr-2">
            <Button type="submit" disabled={isDisbaled} outline={true} size="xs">
              <AppIcon icon="check" />
            </Button>
            <Button disabled={isDisbaled} onClick={this.handleCancel} outline={true} size="xs">
              <AppIcon icon="cancel" />
            </Button>
          </span>
        ) : null}
      </form>
    );
  }
}
