import * as React from 'react';
import { localizedStrings } from '../../localizedStrings';
import { Link } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import { inject } from 'mobx-react';
import { AppIcon } from '../icons/Icon';

interface Props {
  rootStore?: RootStore;
}

@inject('rootStore')
export class SettingsLink extends React.Component<Props> {
  // eslint-disable-next-line
  render() {
    return (
      <Link
        // eslint-disable-next-line
        to={`/${this.props.rootStore!.projectId}/settings/emails`}
        className="btn btn-secondary d-flex mr-1"
        role="button"
      >
        <AppIcon icon="settings" />
        {
          // eslint-disable-next-line
          this.props.rootStore!.userCanEdit ? <div>{localizedStrings.settings.settings}</div> : null
        }
      </Link>
    );
  }
}
