import * as React from 'react';
import { Category } from '@eir/core';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import NavItem from 'reactstrap/lib/NavItem';
import { UIStore } from 'stores/UIStore';
import { RootStore } from 'stores/RootStore';
import { AppIcon } from '../icons/Icon';
import { toasts } from '../../shared';
import { ChiefCategory } from '@eir/core/dist/models/features';
import { CategoriesStore } from 'stores/CategoriesStore';
import { CustomNavCategory } from './TopMenu';

interface Props {
  uiStore?: UIStore;
  rootStore?: RootStore;
  categoriesStore?: CategoriesStore;
  category: Category | ChiefCategory | CustomNavCategory;
  link: string;
  title?: string;
  // eslint-disable-next-line
  activeStyle?: React.CSSProperties | any;
  // eslint-disable-next-line
  style: React.CSSProperties | any;
  iconStyle?: React.CSSProperties;
}
interface State {
  showEditIcons: boolean;
}

@inject('uiStore', 'rootStore', 'categoriesStore')
@observer
export class TopMenuItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showEditIcons: false,
    };
  }

  // eslint-disable-next-line
  showEditIcons = () => {
    this.setState({ showEditIcons: true });
  };

  // eslint-disable-next-line
  hideEditIcons = () => {
    this.setState({ showEditIcons: false });
  };

  // eslint-disable-next-line
  handleNavLinkClick = (e: { preventDefault: () => void }) => {
    const { rootStore, category } = this.props;
    // eslint-disable-next-line
    if (!rootStore!.userExist && (category as Category).data().isProtected) {
      e.preventDefault();
    }
  };
  // check if the category variable is of Type Category or CustomNavCategory, and NOT Chief
  // eslint-disable-next-line
  isObjectCategory = (object: any): object is Category => {
    return 'isProtected' in object.data();
  };

  // eslint-disable-next-line
  render() {
    const { rootStore, category, link, title, activeStyle, style, iconStyle } = this.props;
    const categoryName = category.data().name;
    // eslint-disable-next-line
    const userExists = rootStore!.userExist;
    const lockedIcon = <AppIcon style={iconStyle} color="white" icon="lock" />;
    const categoryIsLocked = this.isObjectCategory(category) ? category.data().isProtected : false;

    return (
      <NavItem onMouseEnter={this.showEditIcons} onMouseLeave={this.hideEditIcons}>
        <NavLink
          activeStyle={activeStyle}
          className="nav-link"
          style={style}
          to={link}
          onClick={this.handleNavLinkClick}
        >
          <div className="d-flex">
            {categoryIsLocked && !userExists ? (
              <del
                onClick={
                  // eslint-disable-next-line
                  () => {
                    toasts.warning('Logga in för att se innehållet');
                  }
                }
              >
                {title ? title : categoryName}
              </del>
            ) : title ? (
              title
            ) : (
              categoryName
            )}
            {categoryIsLocked ? lockedIcon : null}
          </div>
        </NavLink>
        {/*uiStore!.draftMode && showEditIcons && <CategoryActions category={category} />*/}
      </NavItem>
    );
  }
}
