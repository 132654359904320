import { ApplicationStyle } from '@eir/core';
import { inject } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { localizedStrings } from '../../localizedStrings';
import { RootStore } from '../../stores/RootStore';
import { ThemeContext } from '../App/App';
import './Footer.css';

interface Props {
  rootStore?: RootStore;
}

const dt = new Date();

@inject('rootStore')
export class Footer extends React.Component<Props> {
  render(): React.ReactNode {
    return (
      <ThemeContext.Consumer>
        {(appStyle: ApplicationStyle): React.ReactNode => {
          return (
            <footer className="d-flex p-1 pr-2 pl-2" style={{ backgroundColor: appStyle.primary, fontSize: '14px' }}>
              <div className="text-white">&copy; {dt.getFullYear()} Softwerk AB. All rights reserved. </div>
              <Link
                className="text-white pr-4 ml-auto"
                to={
                  // eslint-disable-next-line
                  `/${this.props.rootStore!.projectId}/privacy-policy`
                }
              >
                {localizedStrings.global.privacyPolicy}
              </Link>
              <Link
                className="text-white"
                to={
                  // eslint-disable-next-line
                  `/${this.props.rootStore!.projectId}/term-of-use`
                }
              >
                {localizedStrings.global.termsOfUse}
              </Link>
            </footer>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}
