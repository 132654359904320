import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { localizedStrings } from '../../localizedStrings';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';

interface Props {
  rootStore?: RootStore;
}

@inject('rootStore')
@observer
export class SignInButton extends React.Component<Props> {
  render(): React.ReactNode {
    return (
      // eslint-disable-next-line
      !this.props.rootStore!.userExist && (
        // eslint-disable-next-line
        <Link to={`/${this.props.rootStore!.projectId}/signIn`}>
          <Button color="secondary">{localizedStrings.auth.singInButton}</Button>
        </Link>
      )
    );
  }
}
