import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { RootStore, userType } from '../../stores/RootStore';
import { BodyLayout } from '../Layout/BodyLayout';
import { SideMenu } from '../SideMenu/SideMenu';
import { ArticleMain } from './Article/ArticleMain';
import ContentLoader from 'react-content-loader';
import { Category, Article } from '@eir/core';
import { ArticlesStore } from 'stores/ArticlesStore';
import { toasts } from 'shared';
import { ChiefCategory } from '@eir/core/dist/models/features';

type PathParamsType = {
  projectId: string;
  categoryId: string;
  articleId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  categoriesStore?: CategoriesStore;
  rootStore?: RootStore;
  articlesStore?: ArticlesStore;
};

interface State {
  userIsAdmin: boolean;
}

// eslint-disable-next-line
const ListLoader = () => (
  <div className="p-3">
    <ContentLoader height={160} width={160} speed={5} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <circle cx="10" cy="20" r="8" />
      <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="50" r="8" />
      <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="80" r="8" />
      <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="110" r="8" />
      <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="140" r="8" />
      <rect x="25" y="135" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="170" r="8" />
      <rect x="25" y="165" rx="5" ry="5" width="220" height="10" />
    </ContentLoader>
  </div>
);

@inject('categoriesStore', 'rootStore', 'articlesStore')
@observer
class BodyComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userIsAdmin: false,
    };
  }
  componentDidMount(): void {
    // eslint-disable-next-line
    this.props.rootStore!.isUserLegalInProject().then((result: userType): void => {
      if (['GLOBAL_ADMIN', 'PROJECT_ADMIN'].includes(result)) {
        this.setState({ userIsAdmin: true });
      }
    });
  }
  setDocTitle(articleId: string, categoryId: string, projectId: string | null): void {
    if (!articleId) {
      // eslint-disable-next-line
      const { project } = this.props.rootStore!;
      // eslint-disable-next-line
      const cat: Category | ChiefCategory | undefined = this.props.categoriesStore!.getCategoryById(categoryId);
      if (project && cat) {
        // eslint-disable-next-line
        document.title = `${this.props.rootStore!.project.data().name} | ${cat.data().name}`;
      }
    }
    if (articleId) {
      // eslint-disable-next-line
      const { project } = this.props.rootStore!;
      // eslint-disable-next-line
      const artic: Article | undefined = this.props.articlesStore!.articleById(articleId);
      if (project && artic) {
        document.title = `${project.data().name} | ${artic.data().name}`;
      }
    }
  }

  isCategoryLocked = (): boolean => {
    const { categoriesStore, rootStore, match } = this.props;
    const { categoryId } = match.params;
    // eslint-disable-next-line
    if (rootStore!.userExist) {
      return false;
    }
    // eslint-disable-next-line
    return !categoriesStore!.categoryAuth(categoryId);
  };

  /** check if the category is protected and user does exist or not */
  canNotBeAccessed = (): boolean => {
    const { categoriesStore, rootStore, match } = this.props;
    const { categoryId } = match.params;
    // eslint-disable-next-line
    const result = categoriesStore!.isCategoryProtected(categoryId) && !rootStore!.userExist;
    return result;
  };

  printAllArticles = (): void => {
    // eslint-disable-next-line
    const { allArticles } = this.props.articlesStore!;
    const category = this.props.match.params.categoryId;
    const article = this.props.match.params.articleId;
    if (category && !article) {
      let allContent = '';
      const thisCategoryArticles = allArticles.filter((a) => a.data().category === category);
      thisCategoryArticles.forEach((b) => {
        allContent += ` <hr><center> Artikel: ${b.data().name}</center><hr>`;
        allContent += b.data().content;
        allContent += '<br><br><br>';
      });
      const theTitle = this.props.match.params.categoryId;
      const win = window.open();
      // eslint-disable-next-line
      win!.document.title = theTitle;
      // eslint-disable-next-line
      win!.document.body.innerHTML = allContent;
      // eslint-disable-next-line
      win!.focus();
      // eslint-disable-next-line
      win!.setTimeout(() => {
        // eslint-disable-next-line
        win!.print();
      }, 3000);
    } else {
      toasts.warning('Something was wrong!');
    }
  };

  render(): React.ReactNode {
    const { rootStore, match } = this.props;
    const { categoryId, articleId } = match.params;
    // eslint-disable-next-line
    const { currentArticle } = this.props.articlesStore!;
    // eslint-disable-next-line
    const { project, projectId } = rootStore!;

    this.setDocTitle(articleId, categoryId, projectId);

    // eslint-disable-next-line
    const sideMenu = project ? <SideMenu categoryId={categoryId!} /> : <ListLoader />;
    const content = project ? (
      <Route exact={true} path={'/:projectId/category/:categoryId/:articleId'} component={ArticleMain} />
    ) : null;

    return this.canNotBeAccessed() ? (
      <Redirect
        to={
          // eslint-disable-next-line
          `/${this.props.rootStore!.projectId}`
        }
      />
    ) : (
      <>
        <BodyLayout sideMenu={sideMenu} content={content} />
        {currentArticle.length === 0 && !articleId && categoryId && this.state.userIsAdmin && (
          <button
            className="btn btn-circle btn-xl"
            data-toggle="tooltip"
            data-placement="left"
            title="skriva ut alla artiklar"
            onClick={this.printAllArticles}
          >
            Skriv ut
          </button>
        )}
      </>
    );
  }
}

export const Body = withRouter(BodyComponent);
