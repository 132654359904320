import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { localizedStrings } from '../../localizedStrings';
import { RootStore } from '../../stores/RootStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { AppIcon } from '../icons/Icon';
import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';
import { Category } from '@eir/core';
import { ProtectCategoryData } from '@eir/core';

interface Props {
  rootStore?: RootStore;
  categoriesStore?: CategoriesStore;
}
interface State {
  allCategories: Category[];
}

@inject('rootStore', 'categoriesStore')
@observer
export class CategorySettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      allCategories: [],
    };
  }
  componentDidMount(): void {
    const { categoriesStore } = this.props;
    // eslint-disable-next-line
    this.setState({ allCategories: categoriesStore!.returnAllCetgories });
  }

  onCheckboxBtnClick(category: Category): void {
    const { categoriesStore } = this.props;
    const currentStatus = category.data().isProtected;
    // eslint-disable-next-line
    const data: ProtectCategoryData = { categoryId: category.id!, status: !currentStatus };
    // eslint-disable-next-line
    categoriesStore!.protectCategory(data);
    const allCats = this.state.allCategories;
    const index = allCats.findIndex((c): boolean => c.id === data.categoryId);
    const found = allCats[index];
    const predata = found.data();
    // eslint-disable-next-line
    found.data = () => ({ ...predata, isProtected: !currentStatus });
    allCats[index] = found;

    this.setState({ allCategories: allCats });
  }

  render(): React.ReactNode {
    const { rootStore } = this.props;
    const { allCategories } = this.state;
    // eslint-disable-next-line
    const hasCategories = allCategories && rootStore!.customNavigation.length > 0;
    return (
      <div className="p-3">
        <h3 className="pb-3">{localizedStrings.settings.category}</h3>
        {hasCategories &&
          allCategories.map((category) => (
            <div
              key={
                // eslint-disable-next-line
                category.id!
              }
              className="p-2 bp-4"
            >
              <Label>
                <Button onClick={(): void => this.onCheckboxBtnClick(category)}>
                  <AppIcon icon={category.data().isProtected ? 'lock' : 'unlocked'} />
                </Button>
                &nbsp;{category.data().name}
              </Label>
            </div>
          ))}
      </div>
    );
  }
}
