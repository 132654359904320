export interface LocalizedStrings {
  auth: {
    singInButton: string;
    signOutButton: string;
    emailTitle: string;
    emailPlaceholder: string;
    passwordTitle: string;
    passwordPlaceholder: string;
    logout: string;
    error: {
      unknown: string;
      invalidCredentials: string;
    };
    wrongProject: string;
  };
  global: {
    save: string;
    doNotSave: string;
    cancel: string;
    preview: string;
    edit: string;
    delete: string;
    loading: string;
    startApplication: string;
    searchResult: string;
    search: string;
    feedback: string;
    feedbackSubject: string;
    privacyPolicy: string;
    termsOfUse: string;
    fileFromatError: string;
    print: string;
    create: string;
    clear: string;
  };
  publish: {
    publish: string;
    modalHeader: string;
    modalBody: string;
    start: string;
    publishOk: string;
    publishFail: string;
    applicationMode: string;
    editMode: string;
    publishMode: string;
    defaultPublishMessage: string;
    setPublishMessage: string;
  };
  settings: {
    settings: string;
    updateOk: string;
    email: string;
    emailSettings: string;
    addEmailsHint: string;
    emailAlreadyExist: string;
    category: string;
    accountmng: string;
    changeAdminPass: string;
    changeStaffPass: string;
    passChangeSuccess: string;
    passChangeFail: string;
    emailError: string;
  };
  sorting: {
    articleSort: string;
    sectionSort: string;
    articleEdit: string;
    sectionEdit: string;
    titleForm: string;
    cancelSorting: string;
    saveSorting: string;
    sortingArticlesHasChanged: string;
    sortingSectionsHasChanged: string;
    sortingHasChanged: string;
  };
  article: {
    draftModalHeader: string;
    draftModalBody: string;
    articleSavedOk: string;
    deleteModalHeader: string;
    deleteModalBody: string;
    deleteOk: string;
    addOk: string;
    sortingSaved: string;
    addNewArticle: string;
    wrongArticleName: string;
    notFoundWarning: string;
  };
  category: {
    lock: string;
    lockCategoryInputHint: string;
    lockOk: string;
    protectOk: string;
    lockFail: string;
    protectFail: string;
    login: string;
    password: string;
    pinCheckNotValid: string;
    pinAddNotValid: string;
    notFoundWarning: string;
  };
  section: {
    deleteSectionModalHeader: string;
    deleteSectionModalBody: string;
    sectionDeleted: string;
    sortingSaved: string;
    addOk: string;
    deleteOk: string;
    addNewSection: string;
    wrongSectionName: string;
  };
  project: {
    notLoaded: string;
    noStaffAccount: string;
  };
  forms: {
    placeHolders: {
      createNewSection: string;
      sectionName: string;
    };
    messages: {
      valueToShort: string;
    };
  };
}

export const en: LocalizedStrings = {
  auth: {
    singInButton: 'Sign in',
    signOutButton: 'Sign out',
    emailTitle: 'Email',
    emailPlaceholder: 'your@email.com',
    passwordTitle: 'Password',
    passwordPlaceholder: 'password',
    logout: 'Logout',
    error: { unknown: 'Unknown error', invalidCredentials: 'Invalid email/password' },
    wrongProject: 'You cannot login into this project!',
  },
  global: {
    save: 'Save',
    doNotSave: 'Do not save',
    cancel: 'Cancel',
    edit: 'Edit',
    preview: 'Preview',
    delete: 'Delete',
    loading: 'Loading...',
    startApplication: 'Start Application...',
    searchResult: 'Search Result',
    search: 'Search',
    feedback: 'Feedback',
    feedbackSubject: 'A new feedback submitted on an article',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Legal documents',
    fileFromatError: 'File extention is not supported',
    print: 'Print',
    create: 'Create',
    clear: 'Clear',
  },
  publish: {
    publish: 'Publish',
    modalHeader: 'Publish',
    modalBody: 'Are you sure that you want to publish the changes?',
    start: 'Publishing process started',
    publishOk: 'Publishing done',
    publishFail: 'Publishing failed',
    applicationMode: 'Application mode',
    editMode: 'Edit mode',
    publishMode: 'Released',
    defaultPublishMessage: 'A new content has been published.',
    setPublishMessage: 'Set publish message',
  },
  settings: {
    settings: 'Settings',
    email: 'Email Address',
    emailSettings: 'Email Settings',
    addEmailsHint: 'Add emails to feedback emails.',
    updateOk: 'Configuration saved.',
    emailAlreadyExist: 'Email Already exist',
    category: 'Lock/Unlock Categories',
    accountmng: 'Account Management',
    changeAdminPass: 'Change admin account password',
    changeStaffPass: 'Change staff account password',
    passChangeSuccess: 'Password change succeeded!',
    passChangeFail: 'Changing password failed',
    emailError: 'An error in email!',
  },
  sorting: {
    articleSort: 'Articles',
    sectionSort: 'Sections',
    titleForm: 'Article & Section sorting',
    articleEdit: 'Edit Articles',
    sectionEdit: 'Edit Sections',
    cancelSorting: 'Cancel Sorting',
    saveSorting: 'Save Sorting',
    sortingArticlesHasChanged: ' Sorting has changed',
    sortingSectionsHasChanged: 'Sorting has changed',
    sortingHasChanged: 'Sorting  has changed',
  },
  article: {
    draftModalHeader: 'Not saved content',
    draftModalBody: 'Edited content is not saved.',
    articleSavedOk: 'Article is saved',
    deleteModalHeader: 'Delete article',
    deleteModalBody: 'Are you sure you want to delete the article?',
    deleteOk: 'Article deleted!',
    addOk: 'Article added!',
    sortingSaved: 'Article sorting Saved',
    addNewArticle: 'Add new article',
    wrongArticleName: 'Wrong article name',
    notFoundWarning: 'Article not found!',
  },
  category: {
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Category protection changed',
    protectFail: 'Changing Category Protection Failed',
    notFoundWarning: 'Category not found!',
  },
  section: {
    deleteSectionModalHeader: 'Delete section',
    deleteSectionModalBody: 'Are you sure do you want to delete the section',
    sectionDeleted: 'Section is deleted!',
    sortingSaved: 'Section Sorting saved',
    addOk: 'Section added',
    deleteOk: 'Section deleted',
    wrongSectionName: 'Invalid section name',
    addNewSection: 'Add new section',
  },
  project: {
    notLoaded: 'The project not loaded correctly  or not found.',
    noStaffAccount: 'This project has NO staff account',
  },
  forms: {
    placeHolders: { createNewSection: 'Create new section', sectionName: 'Section Name' },
    messages: { valueToShort: 'The value you have entered is to short' },
  },
};

export const sv: LocalizedStrings = {
  auth: {
    singInButton: 'Logga in',
    signOutButton: 'Logga ut',
    emailTitle: 'E-post',
    emailPlaceholder: 'example@domain.com',
    passwordTitle: 'Lösenord',
    passwordPlaceholder: 'Lösenord',
    logout: 'Logga ut',
    error: { unknown: 'okänt fel', invalidCredentials: 'ogiltigt användarnamn eller lösenord' },
    wrongProject: 'Du har inte behörighet till det här projektet.',
  },
  global: {
    save: 'Spara',
    doNotSave: 'Spara inte',
    cancel: 'Avbryt',
    edit: 'Redigera',
    preview: 'Förhandsvisa',
    delete: 'Ta bort',
    loading: 'Laddar...',
    startApplication: 'Startar Applikation...',
    searchResult: 'sökresultat',
    search: 'Sök...',
    feedback: 'Feedback',
    feedbackSubject: 'Ny feedback skickad',
    privacyPolicy: 'Integritetspolicy',
    termsOfUse: 'Juridiska dokument',
    fileFromatError: 'Filformat stöds inte',
    print: 'Skriv ut',
    create: 'Skapa',
    clear: 'Klar',
  },
  publish: {
    publish: 'Publicera',
    modalHeader: 'Publicera',
    modalBody: 'Är du säker på att du vill publicera ändringarna?',
    start: 'Publicering påbörjad!',
    publishOk: 'Publicering klar!',
    publishFail: 'Publicering misslyckades',
    applicationMode: 'Application mode',
    editMode: 'Redigeringsläge',
    publishMode: 'Publiceringsläge',
    defaultPublishMessage: 'Nytt innehåll har publicerats',
    setPublishMessage: 'Publiceringsmeddelande',
  },
  settings: {
    settings: 'Inställningar',
    email: 'E-post',
    emailSettings: 'E-post inställningar',
    addEmailsHint: 'Lägg till e-post som feedback-mail',
    updateOk: 'konfigurationen sparad',
    emailAlreadyExist: 'E-postadressen finns redan',
    category: 'Lås/lås upp kategorier',
    accountmng: 'Lösenordshantering',
    changeAdminPass: 'Ändra lösenord för administratörkonto',
    changeStaffPass: 'Ändra lösenord för personalkonto',
    passChangeSuccess: 'Lösenordet har ändrats!',
    passChangeFail: 'Byte av lösenord misslyckades',
    emailError: 'Fel i e-postadressen',
  },
  sorting: {
    articleSort: 'Artiklar',
    sectionSort: 'Sektioner',
    titleForm: 'Artiklar&Sektioner sortering',
    articleEdit: 'Redigera artiklar',
    sectionEdit: 'Redigera sektioner',
    cancelSorting: 'Avbryt sortering',
    saveSorting: 'Spara sortering',
    sortingArticlesHasChanged: 'Sortering har ändrats!',
    sortingSectionsHasChanged: 'Sortering har ändrats!',
    sortingHasChanged: 'Sortering har ändrats',
  },
  article: {
    draftModalHeader: 'Innehåller osparat innehåll',
    draftModalBody: 'Redigerat innehåll har inte sparats.',
    articleSavedOk: 'Artikeln sparad',
    deleteModalHeader: 'Radera artikel',
    deleteModalBody: 'Vill du radera artikeln?',
    deleteOk: 'Artikel raderad',
    addOk: 'Artikel har lagts till',
    sortingSaved: 'Artikelsortering sparad',
    addNewArticle: 'Lägg till ny artikel',
    wrongArticleName: 'Artikelnamnet är inte giltigt',
    notFoundWarning: 'Artikeln hittades inte!',
  },
  category: {
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Category protection changed',
    protectFail: 'Changing Category Protection Failed',
    notFoundWarning: 'Kategori hittades inte!',
  },
  section: {
    deleteSectionModalHeader: 'Radera Sektion',
    deleteSectionModalBody: 'Vill du verkligen radera sektionen?',
    sectionDeleted: 'Sektionen raderad',
    sortingSaved: 'Sektionssortering sparad',
    addOk: 'Sektion har lagts till',
    deleteOk: 'Sektion raderad',
    addNewSection: 'Lägg till ny sektion',
    wrongSectionName: 'Sektionsnamnet är inte giltigt',
  },
  project: {
    notLoaded: 'Projektet laddades inte in korrekt eller hittades inte',
    noStaffAccount: 'Projektet här ingen personalkonto',
  },
  forms: {
    placeHolders: { createNewSection: 'Lägg till ny sektion', sectionName: 'Sektionsnamn' },
    messages: { valueToShort: 'The value you have entered is to short' },
  },
};

export const localizedStrings: LocalizedStrings = sv;
