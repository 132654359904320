import { Image } from '@eir/core';
import 'font-awesome/css/font-awesome.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/image.min.js';
import * as $ from 'jquery';
import { inject } from 'mobx-react';
import * as React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import * as uuid from 'uuid/v4';
import { defaultEditorConfig } from '../../../Froala/config';
import '../../../Froala/custom-image-manager.js';
import '../../../Froala/custom-link.js';
import '../../../Froala/sv.js';
import { localizedStrings } from '../../../localizedStrings';
import { toasts } from '../../../shared';
import { RootStore } from '../../../stores/RootStore';
import './Article.css';
import { LinkTypes, checkLinkType } from '../../FrameText/LinkTypeChecker/LinkTypeChecker';
import { CategoriesStore } from 'stores/CategoriesStore';

declare global {
  interface Window {
    // eslint-disable-next-line
    $: any;
  }
}
window.$ = $;

interface Props {
  rootStore?: RootStore;
  editStatus: boolean;
  onModelChange: (newContent: string) => void;
  content: string;
  className?: string;
  categoriesStore?: CategoriesStore;
}

interface State {
  loadingImage: boolean;
}

@inject('rootStore', 'categoriesStore')
export class ArticleEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { loadingImage: false };
  }
  // eslint-disable-next-line
  editor: any;
  container: HTMLDivElement;

  componentDidMount(): void {
    this.adjustEditorSize();
    window.addEventListener('resize', this.adjustEditorSize);
    this.customizeImageUploadAndManager();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.adjustEditorSize);
    this.editor.$element
      .off('froalaEditor.image.beforeUpload', this.beforeUpload)
      .off('froalaEditor.imageManager.beforeImagesLoaded', this.beforeImagesLoaded)
      .off('froalaEditor.link.beforeInsert', this.beforeLinkInsert);
  }

  adjustEditorSize = (): void => {
    const editorWrapperYPadding = 2 * 16;
    // eslint-disable-next-line
    $('.fr-element').height($('#editor-wrapper').height()! - $('.fr-toolbar').outerHeight()! - editorWrapperYPadding);
  };

  render(): React.ReactNode {
    return (
      <div className="editor-wrapper" id="editor-wrapper">
        {this.state.loadingImage && (
          <div id="overlay">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="spinner" />
            </div>
          </div>
        )}
        <FroalaEditor
          // eslint-disable-next-line
          ref={(ref: any) => (this.editor = ref!)}
          tag="textarea"
          config={defaultEditorConfig}
          model={this.props.content}
          onModelChange={(newContent: string): void => {
            if (this.props.editStatus) {
              this.props.onModelChange(newContent);
            }
          }}
        />
      </div>
    );
  }

  private customizeImageUploadAndManager = (): void => {
    this.editor.$element
      .on('froalaEditor.image.beforeUpload', this.beforeUpload)
      .on('froalaEditor.imageManager.beforeImagesLoaded', this.beforeImagesLoaded)
      .on('froalaEditor.link.beforeInsert', this.beforeLinkInsert)
      .on('froalaEditor.paste.afterCleanup', this.urlAutoLink);
  };

  /**
   * hook on upload image of the editor
   * - return false to stop the default editor upload process
   * - After uploaded is succeeded the image will be injected inside the current editor instance
   */
  // eslint-disable-next-line
  private beforeUpload = async (e: any, currentEditor: any, images: any): Promise<void> => {
    this.setState({ loadingImage: true });
    toasts.warning('Vänta på "Laddar Bild..."');
    const { rootStore } = this.props;
    // eslint-disable-next-line
    const { storagePath, currentUser } = rootStore!;
    // eslint-disable-next-line
    const metadata = { customMetadata: { uploadedBy: currentUser!.uid, imageId: '' } };
    const file = images[0];
    const ext = this.getFileExt(file);

    if (ext === 'svg') {
      toasts.error(localizedStrings.global.fileFromatError);
      this.setState({ loadingImage: false });
      return;
    }
    try {
      // eslint-disable-next-line
      const { ref } = await rootStore!.appStorage.ref().child(`${storagePath}${uuid()}.${ext}`).put(file, metadata);

      const url = await ref.getDownloadURL();
      if (url && currentEditor.image.get()) {
        currentEditor.image.insert(url, false, null, currentEditor.image.get(), null);
      }
      this.setState({ loadingImage: false });
    } catch (error) {
      toasts.error(error);
      this.setState({ loadingImage: false });
    }
    this.setState({ loadingImage: false });
    return;
  };

  // eslint-disable-next-line
  private urlAutoLink = (e: any, currentEditor: any, pastedContent: any) => {
    if (/^<a.*>.*<\/a>/i.test(pastedContent)) {
      const domNodes = $($.parseHTML(pastedContent));
      // eslint-disable-next-line
      const element = domNodes[0] as any;
      const { href, text } = this.beforeLinkInsert(e, currentEditor, element.href, '', {});
      return `<a href="${href}">${text}</a>`;
    }
    return pastedContent;
  };

  // eslint-disable-next-line
  private beforeImagesLoaded = (e: any, currentEditor: any): void => {
    const { rootStore } = this.props;
    // eslint-disable-next-line
    rootStore!.loadImages().then((images) => {
      currentEditor.events.trigger('imageManager.imagesLoaded');
      currentEditor.imageManager.processLoadedImages(this.prepareGallery(images));
    });
  };

  // eslint-disable-next-line
  private beforeLinkInsert = (e: any, editor: any, link: string, text: string, attrs: any) => {
    const { rootStore } = this.props;
    // eslint-disable-next-line
    const pid = rootStore!.projectId;
    let finalLink;
    if (link.startsWith('#')) {
      finalLink = link;
      if (this.isEmptyOrWhiteSpaces(text)) {
        text = `Anchorlink`;
      }
      return { href: finalLink, text, attrs };
    }
    try {
      const url = new URL(link);
      // eslint-disable-next-line
      const theLinkType = checkLinkType(url, pid!);

      if (theLinkType === LinkTypes.internalArticle) {
        const urlArray = url.pathname.split('/');
        const articleId = urlArray.pop();
        const categoryId = urlArray[urlArray.length - 1];
        // eslint-disable-next-line
        const isChief: boolean = this.props.categoriesStore!.isCategoryChief(categoryId) ? true : false;
        const isChangelog: boolean = categoryId === 'changelog';
        if (isChief) {
          finalLink = `/chief-articles/${articleId}${url.hash}`;
        } else if (isChangelog) {
          finalLink = `/changelog-articles/${articleId}${url.hash}`;
        } else {
          finalLink = `/articles/${articleId}${url.hash}`;
        }
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `Länk till artikeln ${articleId}`;
          if (url.hash) {
            text = text + '(anchorlink)';
          }
        }
      } else if (theLinkType === LinkTypes.internalCategory) {
        const categoryId = url.pathname.split('/').pop();
        // eslint-disable-next-line
        const isChief: boolean = this.props.categoriesStore!.isCategoryChief(categoryId!) ? true : false;
        if (isChief) {
          finalLink = `/chief-categories/${categoryId}`;
        } else {
          finalLink = `/categories/${categoryId}`;
        }
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `Länk till kategorin ${categoryId}`;
        }
      } else if (theLinkType === LinkTypes.internalAbout) {
        finalLink = `/about`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `Länk till hemsida`;
        }
      } else if (theLinkType === LinkTypes.internalExternal) {
        const categoryId = url.pathname.split('/').pop();
        finalLink = `/externals/${categoryId}`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `Länk till external ${categoryId}`;
        }
      } else if (theLinkType === LinkTypes.internalPolicy) {
        const id = url.pathname.split('/').pop();
        finalLink = `/${id}`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `Länk till ${id}`;
        }
      } else if (theLinkType === LinkTypes.internalTerms) {
        const id = url.pathname.split('/').pop();
        finalLink = `/${id}`;
        if (this.isEmptyOrWhiteSpaces(text)) {
          text = `Länk till ${id}`;
        }
      } else if (theLinkType === LinkTypes.external) {
        finalLink = url;
        if (this.isEmptyOrWhiteSpaces(text)) {
          const name = url.pathname.split('/')[1];
          text = `Länk till ${url.hostname} [${name}]`;
        }
      }

      return { href: finalLink, text, attrs };
    } catch (_) {
      return {
        href: link,
        text,
        attrs,
      };
    }
  };

  private getFileExt(file: File): string {
    return String(file.name.split('.').pop()?.toString()).toLowerCase();
  }

  private prepareGallery(
    images: Image[],
  ): {
    url: string;
    thumb: string;
  }[] {
    return images.map((image) => ({ url: image.data().resourceUrl, thumb: image.data().resourceUrl }));
  }

  private isEmptyOrWhiteSpaces(str: string): boolean {
    return str === null || str.match(/^ *$/) !== null;
  }
}
