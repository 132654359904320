import * as React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Route, RouteComponentProps, withRouter, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { BodyLayout } from './BodyLayout';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { RootStore } from '../../stores/RootStore';
import { SectionsStore } from '../../stores/SectionsStore';
import { AboutInfo } from '../AboutInfo/AboutInfo';
import { Auth } from '../Auth/Auth';
import { ArticleShortUrl } from '../Body/ArticleShortUrl/ArticleShortUrl';
import { Body } from '../Body/Body';
import { CategoriesAuth } from '../Body/CategoryAuth';
import { ExternalContent } from '../ExternalContent/ExternalContent';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { SearchArticle } from '../Search/SearchArticle';
import { SearchResults } from '../Search/SearchResults';
import { Settings } from '../Settings/Settings';
import { StaticPage } from '../StaticPage/StaticPage';
import { TopMenu } from '../TopMenu/TopMenu';
import { CategoryShortUrl } from '../Body/CategoryShortUrl/CategoryShortUrl';

type PathParamsType = {
  categoryId: string;
  searchText: string;
  articleId: string;
};
type Props = RouteComponentProps<PathParamsType> & {
  rootStore?: RootStore;
  categoriesStore: CategoriesStore;
  sectionsStore: SectionsStore;
  articlesStore: ArticlesStore;
};

const Loader: React.FC<{}> = () => {
  return <div>Loading...</div>;
};

@inject('rootStore')
@observer
export class LayoutComponent extends React.Component<Props> {
  render(): React.ReactNode {
    // eslint-disable-next-line
    const { projectId, userExist, currentUser } = this.props.rootStore!;
    const { categoriesStore, sectionsStore, articlesStore } = this.props;
    return (
      <Provider categoriesStore={categoriesStore} sectionsStore={sectionsStore} articlesStore={articlesStore}>
        <Router>
          <React.Fragment>
            <ToastContainer transition={Slide} hideProgressBar={true} />

            {(projectId === 'polis_appen' || projectId === 'softwerk') && !userExist && !currentUser ? (
              <div className="app-content">{this.getGuestRoutes()}</div>
            ) : (
              <React.Fragment>
                <Header />
                <TopMenu />
                <div className="app-content">{this.getRoutes()}</div>
              </React.Fragment>
            )}

            <Footer />
          </React.Fragment>
        </Router>
      </Provider>
    );
  }

  private getRoutes(): React.ReactNode {
    return (
      <React.Fragment>
        <Route path={'/:projectId/external/:folder'} exact={true} component={ExternalContent} />
        <Route
          path={'/:projectId/privacy-policy'}
          exact={true}
          render={(): React.ReactNode => <StaticPage pageId="privacy-policy" />}
        />
        <Route
          path={'/:projectId/term-of-use'}
          exact={true}
          render={(): React.ReactNode => <StaticPage pageId="term-of-use" />}
        />
        <Route path={'/:projectId'} exact={true} component={AboutInfo} />
        <Route path={'/:projectId/about'} exact={true} component={AboutInfo} />
        <Route path={'/:projectId/category/:categoryId'} exact={true} component={Body} />
        <Route path={'/:projectId/category/:categoryId/:articleId'} exact={true} component={Body} />
        <Route path={'/:projectId/pin-check/:categoryId'} exact={true} component={CategoriesAuth} />
        <Route path={'/:projectId/articles/:articleId'} exact={true} component={ArticleShortUrl} />
        <Route path={'/:projectId/chief-articles/:articleId'} exact={true} component={ArticleShortUrl} />
        <Route path={'/:projectId/changelog-articles/:articleId'} exact={true} component={ArticleShortUrl} />
        <Route path={'/:projectId/categories/:categoryId'} exact={true} component={CategoryShortUrl} />
        <Route path={'/:projectId/chief-categories/:categoryId'} exact={true} component={CategoryShortUrl} />
        <Route path={'/:projectId/settings/:settingsPage'} exact={true} component={Settings} />

        <Route
          path={
            // eslint-disable-next-line
            `/${this.props.rootStore!.projectId}/signIn`
          }
          exact={true}
          component={Auth}
        />
        <Route
          path={'/:projectId/search/:searchText'}
          render={(searchProps): React.ReactNode => {
            // eslint-disable-next-line
            const sideMenu = <SearchResults searchText={searchProps.match!.params.searchText} />;
            const content = <Route path={'/:projectId/search/:searchText/:articleId'} component={SearchArticle} />;

            return <BodyLayout sideMenu={sideMenu} content={content} />;
          }}
        />
      </React.Fragment>
    );
  }

  private getGuestRoutes(): React.ReactNode {
    // eslint-disable-next-line
    const GuestUser = React.lazy(() => import(`../ProjectSpecific/${this.props.rootStore!.projectId}/GuestUser`));
    return (
      <React.Fragment>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route
              path={'/:projectId/privacy-policy'}
              exact={true}
              render={(): React.ReactNode => <StaticPage pageId="privacy-policy" />}
            />
            <Route
              path={'/:projectId/term-of-use'}
              exact={true}
              render={(): React.ReactNode => <StaticPage pageId="term-of-use" />}
            />
            <Route path={'/:projectId'} exact={true} component={GuestUser} />
            <Route
              path={
                // eslint-disable-next-line
                `/${this.props.rootStore!.projectId}/signIn`
              }
              exact={true}
              component={Auth}
            />
            <Route component={GuestUser} />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    );
  }
}

export const Layout = withRouter(LayoutComponent);
