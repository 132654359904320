import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { localizedStrings } from '../../../localizedStrings';
import { RootStore } from '../../../stores/RootStore';
import { AppIcon } from '../../icons/Icon';
import { Fab, Action } from '../../TinyFab/Fab';
import '../../TinyFab/FabStyles.css';
import { ApplicationStyle } from '@eir/core';

interface Props {
  rootStore?: RootStore;
  appStyle: ApplicationStyle;
}

@inject('rootStore')
@observer
class FabButton extends React.Component<Props> {
  onPrint = (): void => {
    const tempFrame = document.getElementById('iprintable') as HTMLIFrameElement;
    const theTitle = `${document.title} (${window.location.host})`;
    const win = window.open();
    // eslint-disable-next-line
    win!.document.title = theTitle;
    // eslint-disable-next-line
    win!.document.body.innerHTML = tempFrame!.contentWindow!.document.documentElement.innerHTML;
    // eslint-disable-next-line
    win!.focus();
    // eslint-disable-next-line
    win!.print();
  };

  generateMailTo = (): void => {
    // eslint-disable-next-line
    const { configurationFeedbackEmails: confNew } = this.props.rootStore!;
    let mailTo = '';
    if (confNew) {
      const temp = confNew.join(',');
      mailTo = `mailto:${temp}?subject=${localizedStrings.global.feedbackSubject}&body= Article Link: ${window.location.href}`;
      window.open(mailTo);
    }
  };

  render(): React.ReactNode {
    return (
      <div data-toggle="tooltip" data-placement="left" title="Feedback & Skrivut">
        <Fab
          mainButtonStyles={{
            backgroundColor: `${this.props.appStyle.primaryLight}85`,
            color: this.props.appStyle.primaryDark,
          }}
          position={{ bottom: '25px', right: '10px' }}
          icon={<AppIcon icon="mailBulk" />}
          event="hover"
        >
          {
            // eslint-disable-next-line
            this.props.rootStore!.configurationFeedbackEmails ? (
              <Action
                style={{
                  backgroundColor: `${this.props.appStyle.primaryLight}`,
                  color: this.props.appStyle.primaryDark,
                }}
                data-toggle="tooltip"
                data-placement="left"
                title="skicka Feedback"
                onClick={this.generateMailTo}
              >
                <AppIcon icon="envelope" />
              </Action>
            ) : (
              <Action
                style={{
                  backgroundColor: `${this.props.appStyle.primaryLight}`,
                  color: this.props.appStyle.primaryDark,
                }}
                text="Ingen feedback-e-post är registrerad"
              >
                <AppIcon icon="commentSlash" />
              </Action>
            )
          }

          <Action
            style={{ backgroundColor: `${this.props.appStyle.primaryLight}`, color: this.props.appStyle.primaryDark }}
            data-toggle="tooltip"
            data-placement="left"
            title="Skriv ut"
            onClick={this.onPrint}
          >
            <AppIcon icon="print" />
          </Action>
        </Fab>
      </div>
    );
  }
}

export default FabButton;
