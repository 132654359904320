import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { localizedStrings } from '../../localizedStrings';
import { RootStore } from '../../stores/RootStore';
import FrameText from '../FrameText/FrameText';
import './StaticPage.css';

interface Props {
  rootStore?: RootStore;
  pageId: string;
}

// eslint-disable-next-line
@inject('rootStore')
@observer
export class StaticPage extends React.Component<Props> {
  replaceDynamicText = (data: string): string => {
    // eslint-disable-next-line
    const projectData = this.props.rootStore!.project;
    return (
      data
        // eslint-disable-next-line
        .replace('{{application}}', projectData.data().name!)
        .replace('{{publisher}}', projectData.data().publisher)
        .replace('{{organisation}}', projectData.data().organization)
    );
  };
  // eslint-disable-next-line
  componentDidMount() {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      if (this.props.pageId === 'privacy-policy') {
        document.title = `${project.data().name}  | Privacy Policy`;
      }
      if (this.props.pageId === 'term-of-use') {
        document.title = `${project.data().name} | Legal Documents`;
      }
    }
  }
  // eslint-disable-next-line
  componentDidUpdate() {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      if (this.props.pageId === 'privacy-policy') {
        document.title = `${project.data().name}  | Privacy Policy`;
      }
      if (this.props.pageId === 'term-of-use') {
        document.title = `${project.data().name} | Legal Documents`;
      }
    }
  }

  // eslint-disable-next-line
  render() {
    const { rootStore, pageId } = this.props;
    // eslint-disable-next-line
    const legalDocuments = rootStore!.legalDocuments;

    return legalDocuments ? (
      <div className="static-page">
        <FrameText
          content={
            pageId === 'pricay-policy'
              ? this.replaceDynamicText(legalDocuments.data().privacyPolicy)
              : this.replaceDynamicText(legalDocuments.data().termsOfUse)
          }
          status={true}
        />
      </div>
    ) : (
      <div> {localizedStrings.global.loading} </div>
    );
  }
}
