import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { Button, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { localizedStrings } from '../../localizedStrings';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { AppIcon } from '../icons/Icon';
import { ModalConfirm } from '../SideMenu/Admin/ModalConfirm';
import { Article } from '@eir/core';
import { Fragment } from 'react';
import './UserNav.css';

interface Props {
  articlesStore?: ArticlesStore;
}

interface State {
  publishModal: boolean;
  consistancyModal: boolean;
  publishMessageActive: boolean;
}

@inject('articlesStore')
@observer
export class Publish extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      publishModal: false,
      publishMessageActive: false,
      consistancyModal: false,
    };
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps: Props, prevState: State) {
    // eslint-disable-next-line
    if (prevState.consistancyModal === false && this.props.articlesStore!.consistReport) {
      this.setState({ consistancyModal: true });
    }
  }

  publishMessageInput: HTMLInputElement;

  // eslint-disable-next-line
  publish = () => {
    const msg = this.state.publishMessageActive ? this.publishMessageInput.value : '';
    // eslint-disable-next-line
    this.props.articlesStore!.publish({ notifyMessage: msg });
    this.hideModal();
  };

  // eslint-disable-next-line
  showModal = () => {
    // eslint-disable-next-line
    this.props.articlesStore!.consistReport
      ? this.setState({ consistancyModal: true })
      : this.setState({ publishModal: true });
  };

  // eslint-disable-next-line
  hideModal = () => {
    this.setState({ publishModal: false });
  };

  // eslint-disable-next-line
  toggleConsistModal = () => {
    // eslint-disable-next-line
    this.setState({ consistancyModal: !this.state.consistancyModal });
  };

  // eslint-disable-next-line
  togglePublishMessage = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      publishMessageActive: event.currentTarget.checked,
    });
  };

  // eslint-disable-next-line
  resetConsistData = () => {
    // eslint-disable-next-line
    this.props.articlesStore!.clearConsistancyReport();
    this.setState({ consistancyModal: false });
  };

  // eslint-disable-next-line
  forceToPublish = () => {
    window.alert('Publicering med fel är för nuvarande inte tillåten. Felen måste åtgärdas!');
  };

  // eslint-disable-next-line
  render() {
    const { publishModal: modalOpened } = this.state;
    // eslint-disable-next-line
    const { consistReport, publishSpinner } = this.props.articlesStore!;
    const errorFullArticles: Article[] = [];
    if (consistReport) {
      for (const key in consistReport) {
        // eslint-disable-next-line no-prototype-builtins
        if (consistReport.hasOwnProperty(key)) {
          // eslint-disable-next-line
          const a: Article = this.props.articlesStore!.articleById(key)!;
          errorFullArticles.push(a);
        }
      }
    }
    const title = localizedStrings.publish.publish;

    const messageInput = (
      <span>
        {this.state.publishMessageActive && (
          <FormGroup>
            <input
              className="form-control"
              ref={
                // eslint-disable-next-line
                (ref) => (this.publishMessageInput = ref!)
              }
              autoFocus={true}
            />
          </FormGroup>
        )}
        <FormGroup check={true}>
          <Label check={true}>
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={this.state.publishMessageActive}
              onChange={this.togglePublishMessage}
            />
            {localizedStrings.publish.setPublishMessage}
          </Label>
        </FormGroup>
      </span>
    );

    return (
      <Fragment>
        <ModalConfirm
          opened={modalOpened}
          onYes={this.publish}
          onCancel={this.hideModal}
          header={localizedStrings.publish.modalHeader}
          body={messageInput}
          actionButton={localizedStrings.publish.publish}
        />
        <Modal isOpen={this.state.consistancyModal} toggle={this.toggleConsistModal}>
          <ModalHeader toggle={this.toggleConsistModal} className="text-danger font-weight-bold">
            Fel i publicering
          </ModalHeader>
          <ModalBody>
            {errorFullArticles.map((a) => (
              // eslint-disable-next-line
              <div key={a.id!}>
                <div className="bg-light">
                  <p className="bg-secondary">
                    Fel i artikeln &nbsp;
                    <span className="font-weight-bold font-italic">{a.data().name}</span>
                    &nbsp; i sektionen &nbsp;
                    <span className="font-weight-bold font-italic">{a.data().category}</span>
                  </p>
                  {
                    // eslint-disable-next-line
                    consistReport![a.id!].href.map((h) => (
                      <p key={h.length} className="p-3 mb-2">
                        innehåller en felaktig länk till &nbsp;
                        <span style={{ color: 'blue' }}>{h}</span>
                      </p>
                    ))
                  }
                </div>
                <br />
              </div>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.forceToPublish}>
              Publicera med fel!
            </Button>{' '}
            <Button color="primary" onClick={this.resetConsistData}>
              OK, jag fixar
            </Button>
          </ModalFooter>
        </Modal>

        <Button className="btn btn-primary mr-1" type="button" onClick={this.showModal} disabled={publishSpinner}>
          {publishSpinner ? (
            <AppIcon icon="spinner" title="Publicer..." content="Publicer..." />
          ) : (
            <AppIcon icon="publish" title={title} content={title} />
          )}
        </Button>
      </Fragment>
    );
  }
}
