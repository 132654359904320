import * as React from 'react';
import ContentLoader from 'react-content-loader';

declare global {
  // eslint-disable-next-line
  interface Window {}
}

export class MainLoader extends React.Component {
  render(): React.ReactNode {
    const st = { width: '100%', height: '100%' };
    return (
      <ContentLoader height={800} width={600} speed={3} primaryColor="#f3f3f3" secondaryColor="#d0cece" style={st}>
        <rect x="317" y="118.27" rx="0" ry="0" width="0" height="0" />
        <rect x="269" y="75.27" rx="0" ry="0" width="0" height="0" />
        <rect x="269" y="75.27" rx="0" ry="0" width="0" height="0" />
        <rect x="269" y="75.27" rx="0" ry="0" width="0" height="0" />
        <rect x="129" y="116.27" rx="0" ry="0" width="0" height="0" />
        <rect x="157" y="86.27" rx="0" ry="0" width="0" height="0" />
        <rect x="0.33" y="1.25" rx="0" ry="0" width="604.24" height="43.47" />
        <rect x="0.08" y="46.92" rx="0" ry="0" width="615.86" height="15.12" />
        <rect x="1.33" y="63.92" rx="0" ry="0" width="81" height="700" />
        <rect x="228.33" y="84.92" rx="0" ry="0" width="0" height="0" />
        <rect x="84.33" y="63.58" rx="0" ry="0" width="521.85" height="700" />
        <rect x="335.33" y="283.58" rx="0" ry="0" width="0" height="0" />
      </ContentLoader>
    );
  }
}
