import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/fn/array/includes';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './polyfills';
import './bootstrap.css';
import { AppRoutes } from './routes';

ReactDOM.render(<AppRoutes />, document.getElementById('root') as HTMLElement);
