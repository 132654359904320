/**
 * Steps to configure the app navigation
 * 1- create a .ts file named by the project name under e.g '/src/navigation/' check './demo.ts'
 * 2- import it using the wildcard syntax
 * 3- export it as a key in 'appCustomNavigation' object down; !important: the key must be the 'project id'
 */

import * as ambulansBlekingeLinks from './ambulansBlekinge';
import * as ambulansOrebroLinks from './ambulansOrebro';
import * as ambulansVastmanland from './ambulansVastmanland';
import * as demoLinks from './demo';
import * as testLinks from './test';
import * as lakemedelGavleborgLinks from './lakemedelGavleborg';
import * as polisAppenLinks from './polisAppen';
import * as stramaGavleborgborgLinks from './stramaGavleborg';
import * as stramaJonkopingLinks from './stramaJonkoping';
import * as stramaNationellLinks from './stramaNationell';
import * as ambulansVasternorrland from './ambulansVasternorrland';
import * as ambulansNorrbotten from './ambulansNorrbotten';
import * as stramaOrebroLinks from './stramaOrebro';
import * as ambulansSormlandLinks from './ambulansSormland';
import * as stramaVarmland from './stramaVarmland';
import * as missingPeople from './missingPeople';
import * as raddningstjanstenSyd from './raddningstjanstenSyd';
import * as falckAmbStockholm from './falckAmbStockholm';
import * as falckAmbGotland from './falckAmbGotland';
import * as softwerk from './softwerk';
import * as vcBagis from './vcBagis';
import * as ambulansKronoberg from './ambulansKronoberg';
import * as ambulansOstergotland from './ambulansOstergotland';
import * as faluKommun from './faluKommun';
import * as satersKommun from './satersKommun';
import * as primarvardSormland from './primarvardSormland';

/* eslint-disable @typescript-eslint/camelcase */
export const appCustomNavigation = {
  demo: demoLinks,
  test: testLinks,
  ambulans_blekinge: ambulansBlekingeLinks,
  ambulans_orebro: ambulansOrebroLinks,
  ambulans_vastmanland: ambulansVastmanland,
  lakemedel_gavleborg: lakemedelGavleborgLinks,
  polis_appen: polisAppenLinks,
  strama_gavleborg: stramaGavleborgborgLinks,
  strama_jonkoping: stramaJonkopingLinks,
  strama_nationell: stramaNationellLinks,
  ambulans_vasternorrland: ambulansVasternorrland,
  ambulans_norrbotten: ambulansNorrbotten,
  strama_orebro: stramaOrebroLinks,
  ambulans_sormland: ambulansSormlandLinks,
  strama_varmland: stramaVarmland,
  mps_appen: missingPeople,
  raddningstjansten_syd: raddningstjanstenSyd,
  falck_amb_stockholm: falckAmbStockholm,
  falck_amb_gotland: falckAmbGotland,
  softwerk: softwerk,
  vc_bagis: vcBagis,
  ambulans_kronoberg: ambulansKronoberg,
  ambulans_ostergotland: ambulansOstergotland,
  falu_kommun: faluKommun,
  saters_kommun: satersKommun,
  primarvard_sormland: primarvardSormland,
};
