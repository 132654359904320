export const defaultEditorConfig = {
  key: 'mA4D4D2E2vA1E1F1C4B8C7A5E1E5E4iexC-16mmE-11nvB2le==',
  language: 'sv',
  charCounterCount: false,
  pasteImage: false,
  toolbarButtons: [
    'fullscreen',
    'fontFamily',
    'fontSize',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'formatUL',
    'formatOL',
    '|',
    'color',
    'paragraphStyle',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    'lineHeight',
    '|',
    'insertLink',
    'insertImage',
    'insertVideo',
    'embedly',
    'insertTable',
    '|',
    'quote',
    'insertHR',
    'undo',
    'redo',
    'clearFormatting',
    'selectAll',
    'html',
    '|',
    'fontAwesome',
    'print',
    'help',
  ],
  fontSize: ['6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '30', '36', '48', '60', '72', '96'],
  entities:
    '&lt;&gt;&iexcl;&cent;&pound;&curren;&yen;&brvbar;&sect;&uml;&copy;&ordf;&laquo;&not;&shy;&reg;&macr;&deg;&sup2;' +
    '&sup3;&acute;&micro;&para;&middot;&cedil;&sup1;&ordm;&raquo;&frac14;&frac12;&frac34;&iquest;&Yacute;&THORN;' +
    '&szlig;&Alpha;&Beta;&Gamma;&Delta;&Epsilon;&Zeta;&Eta;&Theta;&Iota;&Kappa;&Lambda;&Mu;&Nu;&Xi;&Omicron;&Pi;&Rho;' +
    '&Sigma;&Tau;&Upsilon;&Phi;&Chi;&Psi;&Omega;&alpha;&beta;&gamma;&delta;&epsilon;&zeta;&eta;&theta;&iota;&kappa;' +
    '&lambda;&mu;&nu;&xi;&omicron;&pi;&rho;&sigmaf;&sigma;&tau;&upsilon;&phi;&chi;&psi;&omega;&thetasym;&upsih;&piv;' +
    '&ensp;&emsp;&thinsp;&lsquo;&rsquo;&sbquo;&ldquo;&rdquo;&bdquo;&dagger;&Dagger;&bull;&hellip;&permil;&lsaquo;' +
    '&rsaquo;&oline;&frasl;&euro;&image;&weierp;&real;&trade;&alefsym;&larr;&uarr;&rarr;&darr;&harr;&crarr;&lArr;' +
    '&uArr;&rArr;&dArr;&hArr;&forall;&part;&exist;&empty;&nabla;&isin;&notin;&ni;&prod;&sum;&minus;&lowast;&radic;' +
    '&prop;&infin;&ang;&and;&or;&cap;&cup;&int;&there4;&sim;&cong;&asymp;&ne;&equiv;&le;&ge;&sub;&sup;&nsub;&sube;' +
    '&supe;&oplus;&otimes;&perp;&sdot;&lceil;&rceil;&lfloor;&rfloor;&lang;&rang;&loz;&spades;&clubs;&hearts;&diams;',
};
