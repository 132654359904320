import * as React from 'react';
import { SectionsStore } from '../../../stores/SectionsStore';
import { Section } from '@eir/core';
import { SectionEdit } from './SectionEdit';

interface Props {
  section: Section;
  sectionsStore: SectionsStore;
  categoryId: string;
}

export const SectionActions: React.StatelessComponent<Props> = (props: Props) => (
  <div className="justify-content-between align-items-start p-2  section-header">
    <SectionEdit section={props.section} categoryId={props.categoryId} />
  </div>
);
