import * as React from 'react';
import { FormEvent } from 'react';
import { SectionsStore } from '../../../stores/SectionsStore';
import Input from 'reactstrap/lib/Input';
import { localizedStrings } from '../../../localizedStrings';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import { inject } from 'mobx-react';
import { AppIcon } from '../../icons/Icon';
import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import { toasts } from '../../../shared';
import { CategoriesStore } from '../../../stores/CategoriesStore';

interface Props {
  categoryId: string;
  sectionsStore?: SectionsStore;
  categoriesStore?: CategoriesStore;
}

interface State {
  addIconPressed: boolean;
  newSectionName: string | null;
  form: {
    sectionNameValid: boolean;
    sectionNameDirty: boolean;
  };
}

export const MAX_SECTION_NAME_LENGTH = 120;

@inject('sectionsStore', 'categoriesStore')
export class SectionAdd extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addIconPressed: false,
      newSectionName: '',
      form: {
        sectionNameValid: false,
        sectionNameDirty: false,
      },
    };
  }

  submit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { section } = event.currentTarget;
    const sectionName = section.value.trim();
    const isValid = sectionName !== '' && sectionName.length < MAX_SECTION_NAME_LENGTH;

    if (isValid) {
      // eslint-disable-next-line
      const chief: boolean = this.props.categoriesStore!.isCategoryChief(this.props.categoryId) ? true : false;
      const isChangelog: boolean = this.props.categoryId === 'changelog';
      // eslint-disable-next-line
      this.props.sectionsStore!.addSection(sectionName, this.props.categoryId, chief, isChangelog);
      section.value = '';
    } else {
      toasts.error(localizedStrings.section.wrongSectionName);
    }

    this.setState({
      form: {
        sectionNameDirty: true,
        sectionNameValid: isValid ? true : false,
      },
      addIconPressed: false,
    });
  };

  addIconPressed = (): void => {
    this.setState({ addIconPressed: true });
  };
  onCancel = (): void => {
    this.setState({ addIconPressed: false });
  };

  render(): React.ReactNode {
    const { addIconPressed } = this.state;
    if (addIconPressed) {
      return (
        <form onSubmit={this.submit} className="m-2 p-2">
          <FormGroup>
            <h6> {localizedStrings.forms.placeHolders.createNewSection} </h6>
            <Input type="text" id="section" placeholder={localizedStrings.forms.placeHolders.sectionName} />
            <FormFeedback>{localizedStrings.forms.messages.valueToShort} </FormFeedback>
          </FormGroup>
          <ButtonGroup>
            <Button type="submit" outline={true}>
              <AppIcon icon="check" />
            </Button>
            <Button onClick={this.onCancel} outline={true}>
              <AppIcon icon="cancel" />
            </Button>
          </ButtonGroup>
        </form>
      );
    }
    return (
      <div className="p-2 ml-2">
        <Button onClick={this.addIconPressed}>
          <AppIcon icon="folderPlus" title="Add Section" content={localizedStrings.section.addNewSection} />
        </Button>
      </div>
    );
  }
}
