import {
  Article,
  ArticleData,
  ConsistencyReportData,
  PublishProcess,
  PublishResult,
  SearchService,
  StoreService,
  ExternalDoc,
} from '@eir/core';
import { sortBy } from 'lodash';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import { localizedStrings } from '../localizedStrings';
import { toasts } from '../shared';

export class ArticlesStore {
  @observable
  storeService: StoreService;
  @observable
  searchService: SearchService;

  @observable
  allArticles: Article[] = [];

  @observable
  currentCategoryArticles: Article[] = [];

  @observable
  searchResult: Article[] = [];

  @observable
  searchText = '';

  @observable
  currentBodyArticle = '';

  @observable
  isSortingActive = false;

  @observable
  sortedArticles: Article[] = [];

  sortingAfterModify: boolean;

  @observable
  consistReport: { [key: string]: ConsistencyReportData } | null;

  @observable
  publishSpinner = false;

  // eslint-disable-next-line
  articlesCustomChanges = observable<any>([]);

  constructor(storeService: StoreService, searchService: SearchService) {
    this.storeService = storeService;
    this.searchService = searchService;
    this.loadArticles();
    this.consistReport = null;
  }

  loadExternalDoc = (id: string): Promise<ExternalDoc> => {
    return this.storeService.fetchExternal(id);
  };

  // eslint-disable-next-line
  @action.bound
  async loadArticles() {
    const defaultArticles = await this.storeService.fetchAllArticles();
    const chiefArticles = await this.loadChiefArticles();
    const changelogArticles = await this.loadChangelogArticles();
    runInAction(async () => {
      this.allArticles = defaultArticles.concat(chiefArticles, changelogArticles);
      this.setSearch(this.searchText);
    });
  }

  async loadChiefArticles(): Promise<Article[]> {
    try {
      const chiefArticles = await this.storeService.fetchAllChiefArticles();
      return chiefArticles;
    } catch (e) {
      return [];
    }
  }

  async loadChangelogArticles(): Promise<Article[]> {
    try {
      const changelogArticles = await this.storeService.fetchAllChangelogArticles();
      return changelogArticles;
    } catch (e) {
      return [];
    }
  }

  // eslint-disable-next-line
  clearConsistancyReport(): any {
    this.consistReport = null;
  }

  // eslint-disable-next-line
  articlesByCategoryId(categoryId: string) {
    return this.allArticles.filter((a: Article) => a.data().category === categoryId && a.data().deleted !== true);
  }

  // eslint-disable-next-line
  getOrphanArticles(categoryId: string) {
    return this.allArticles.filter((a) => a.data().category === categoryId && a.data().section === null);
  }

  // eslint-disable-next-line
  @action.bound
  setSearch(searchText = '') {
    this.searchText = searchText;
    this.search(searchText).then((result) => (this.searchResult = result));
  }

  // eslint-disable-next-line
  private async search(searchText = '') {
    const ids = await this.searchService.search(searchText);
    // eslint-disable-next-line
    return await ids.map((id) => this.allArticles.find((article) => article.id === id)!);
  }

  // eslint-disable-next-line
  @action.bound
  setCurrentArticle(articleId: string) {
    this.currentBodyArticle = articleId;
  }

  // eslint-disable-next-line
  @action.bound
  addArticleSortingSameSection(first: Article[], isChief: boolean, isChangelog: boolean) {
    // assigning the new indexes to articles
    this.sortedArticles = [];
    first.forEach((a, i) => {
      const temp = a;
      const tempData = a.data();
      // eslint-disable-next-line
      temp.data = () => ({ ...tempData, orderIndex: i });
      first[i] = temp;
      this.sortedArticles.push(temp);
    }, first);

    // replace new indexed articles in the `allArticles`
    // which user can see the new orders before saving them.
    // due to NOT using component state any more this change should happen here
    first.forEach((a, i) => {
      const found = this.allArticles.find((f) => f.id === a.id);
      if (found) {
        found.data().orderIndex = a.data().orderIndex;
      }
    });
    // and finally sort by `orderIndex` property of each article inside its section.
    this.allArticles = sortBy(this.allArticles, (a) => a.data().orderIndex);

    if (this.sortingAfterModify) {
      this.saveSorting(isChief, isChangelog);
    }
  }

  // eslint-disable-next-line
  @action.bound
  addArticleSortingDiffSections(first: Article[], second: Article[], isChief: boolean, isChangelog: boolean) {
    // assigning the new indexes to articles
    first.forEach((a, i) => {
      const temp = a;
      const tempData = a.data();
      // eslint-disable-next-line
      temp.data = () => ({ ...tempData, orderIndex: i });
      first[i] = temp;
      this.sortedArticles.push(temp);
    }, first);
    first.forEach((a, i) => {
      const found = this.allArticles.find((f) => f.id === a.id);
      if (found) {
        found.data().orderIndex = a.data().orderIndex;
      }
    });

    second.forEach((a, i) => {
      const temp = a;
      const tempData = a.data();
      // eslint-disable-next-line
      temp.data = () => ({ ...tempData, orderIndex: i });
      second[i] = temp;
      this.sortedArticles.push(temp);
    }, second);
    second.forEach((a, i) => {
      const found = this.allArticles.find((f) => f.id === a.id);
      if (found) {
        found.data().orderIndex = a.data().orderIndex;
      }
    });
    this.allArticles = sortBy(this.allArticles, (a) => a.data().orderIndex);

    if (this.sortingAfterModify) {
      this.saveSorting(isChief, isChangelog);
    }
  }

  // eslint-disable-next-line
  @action.bound
  saveSorting(isChief: boolean, isChangelog: boolean) {
    if (!this.sortedArticles.length) {
      return;
    }
    if (isChief) {
      this.storeService
        .updateChiefArticlesOrder(toJS(this.sortedArticles))
        .then(() => {
          if (!this.sortingAfterModify) {
            toasts.success(localizedStrings.article.sortingSaved);
          }
          this.resetSorting();
        })
        .catch((error) => {
          toasts.error(error.message);
        });
    } else if (isChangelog) {
      this.storeService
        .updateChangelogArticlesOrder(toJS(this.sortedArticles))
        .then(() => {
          if (!this.sortingAfterModify) {
            toasts.success(localizedStrings.article.sortingSaved);
          }
          this.resetSorting();
        })
        .catch((error) => {
          toasts.error(error.message);
        });
    } else {
      this.storeService
        .updateArticlesOrder(toJS(this.sortedArticles))
        .then(() => {
          if (!this.sortingAfterModify) {
            toasts.success(localizedStrings.article.sortingSaved);
          }
          this.resetSorting();
        })
        .catch((error) => {
          toasts.error(error.message);
        });
    }
  }

  @action.bound
  async addArticle(article: ArticleData, isChief: boolean, isChangelog: boolean): Promise<Article> {
    let result: Article;
    if (isChief) {
      result = await this.storeService.addChiefArticle(article);
    } else if (isChangelog) {
      result = await this.storeService.addChangelogArticle(article);
    } else {
      result = await this.storeService.addArticle(article);
    }

    toasts.success(localizedStrings.article.addOk);
    this.allArticles.unshift(result);
    this.sortingAfterModify = true;
    const { section, category } = result.data();
    /**
     * @todo for unknown reason/somewhere MOBX caching the previous
     * state and after addition/deletion and after a second render
     * returning the previous state. therefore we make a temp from current state.
     *  this problem should be investigated and this pattern should be optimized
     */
    const temp = this.allArticles.filter(
      (a) => a.data().section === section && a.data().category === category && !a.data().deleted,
    );
    this.addArticleSortingSameSection(temp, isChief, isChangelog);
    return result;
  }

  // eslint-disable-next-line
  @action.bound
  async deleteArticle(article: Article, isChief: boolean) {
    const isChangelog: boolean = article.data().category === 'changelog';
    try {
      if (isChief) {
        // eslint-disable-next-line
        await this.storeService.deleteChiefArticle(article.id!);
      } else if (isChangelog) {
        // eslint-disable-next-line
        await this.storeService.deleteChangelogArticle(article.id!);
      } else {
        // eslint-disable-next-line
        await this.storeService.deleteArticle(article.id!);
      }

      const articleToDeleteIndex = this.allArticles.findIndex((a) => a.id === article.id);
      this.allArticles.splice(articleToDeleteIndex, 1);
      this.sortingAfterModify = true;
      // now get all articles in the section which deletion happened
      const { section, category } = article.data();
      /**
       * @todo for unknown reason/somewhere MOBX caching the previous
       * state and after addition/deletion and after a second render
       * returning the previous state. therefore we make a temp from current state.
       * this problem should be investigated and this pattern should be optimized
       */
      const temp = this.allArticles.filter(
        (a) => a.data().section === section && a.data().category === category && !a.data().deleted,
      );
      this.addArticleSortingSameSection(temp, isChief, isChangelog);
      toasts.success(localizedStrings.article.deleteOk);
    } catch (error) {
      toasts.error(error.message);
    }
  }

  // eslint-disable-next-line
  @computed
  get currentArticle() {
    const article = this.allArticles.find((a) => a.id === this.currentBodyArticle);
    return article ? article.data().content : '';
  }

  // eslint-disable-next-line
  @computed
  get activeArticle() {
    const article = this.allArticles.find((a) => a.id === this.currentBodyArticle);
    return article ? article.id : null;
  }

  // eslint-disable-next-line
  @computed
  get getSearchResult() {
    return this.searchResult;
  }

  // eslint-disable-next-line
  @action.bound
  resetSorting() {
    this.sortedArticles = [];
    this.sortingAfterModify = false;
  }

  // eslint-disable-next-line
  @action.bound
  cancelSorting() {
    this.sortedArticles = [];
    this.sortingAfterModify = false;
    this.loadArticles();
  }

  // eslint-disable-next-line
  async saveArticle(
    id: string,
    isChief: boolean,
    isChangelog: boolean,
    newContent?: string | null,
    newName?: string | null,
  ) {
    try {
      if (isChief) {
        await this.storeService.saveChiefArticle(id, newContent, newName);
      } else if (isChangelog) {
        await this.storeService.saveChangelogArticle(id, newContent, newName);
      } else {
        await this.storeService.saveArticle(id, newContent, newName);
      }
      const index = this.allArticles.findIndex((a) => a.id === id);
      const data = this.allArticles[index].data();

      if (!newContent && !newName) {
        toasts.error('Error updating article, no content or name found.');
        throw Error('Error updating article, no content or name found.');
      }

      if (newContent) {
        // eslint-disable-next-line
        this.allArticles[index].data = () => ({ ...data, content: newContent });
      }

      if (newName) {
        // eslint-disable-next-line
        this.allArticles[index].data = () => ({ ...data, name: newName });
      }

      this.articlesCustomChanges.replace([this.allArticles[index]]);

      toasts.success(localizedStrings.article.articleSavedOk);
    } catch (error) {
      toasts.error(error.message);
    }
  }

  // eslint-disable-next-line
  @computed
  get sortingButtonsEnabled() {
    return this.sortedArticles.length > 0 && !this.sortingAfterModify;
  }

  /**
   * @param {string | null} sectionId - Use null for none section allArticles
   * @param {string} categoryId
   * @returns {Article[]}
   */
  articlesBy({ sectionId, categoryId }: { sectionId: string | null; categoryId: string }): Article[] {
    return this.allArticles.filter((a: Article) => a.data().section === sectionId && a.data().category === categoryId);
  }

  articleById(id: string): Article | undefined {
    return computed(() => this.allArticles.find((a: Article) => a.id === id)).get();
  }

  // eslint-disable-next-line
  @action
  public publish(data: PublishProcess) {
    const strings = localizedStrings.publish;
    this.publishSpinner = true;
    toasts.info(strings.start);

    this.storeService
      .publish(data)
      .then((result: PublishResult) => {
        result.ref.onSnapshot((publishResult: PublishResult) => {
          const { initiatedOn, completedOn, consistencyReport, publishedSuccessful } = publishResult.data();
          const publishingCompleted = initiatedOn && completedOn;
          const articlesHasPublishErrors: string[] = [];

          /* get allArticles names to show simple error using the toast.
           *  a new feature will be using a component to show the details and links to
           *  allArticles that has errors
           */
          if (publishingCompleted && !publishedSuccessful) {
            this.consistReport = consistencyReport;
            Object.keys(consistencyReport).forEach((articleId: string) => {
              const article = this.allArticles.find((a) => a.id === articleId);
              if (article) {
                articlesHasPublishErrors.push(article.data().name);
              } else {
                articlesHasPublishErrors.push(articleId);
              }
            });
            toasts.error(`Issues found in allArticles: ${articlesHasPublishErrors.join(' & ')}`);
            this.publishSpinner = false;
          }

          if (publishingCompleted && publishedSuccessful) {
            toasts.success(strings.publishOk);
            this.publishSpinner = false;
          }
        });
      })
      .catch((error) => {
        toasts.error(error.message);
        this.publishSpinner = false;
      });
  }
}
