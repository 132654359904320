import * as React from 'react';
import { Section, Article } from '@eir/core';
import { Droppable, NotDraggingStyle, DraggingStyle, Draggable } from 'react-beautiful-dnd';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { SectionsStore } from '../../../stores/SectionsStore';
import { DragableArticle } from './DragableArticle';
import { appColors } from '../../App/App';
import '../SideMenu.css';
import { SectionMenuItem } from './SectionMenuItem';
import { SectionAddArticle } from './SectionAddArticle';

interface Props {
  section: Section | null;
  articles: Article[];
  categoryId: string;
  index: number;
  articlesStore?: ArticlesStore;
  sectionsStore?: SectionsStore;
}
// adopting the same style as article draging for section draging [dashhed blue border]
// eslint-disable-next-line
const sortingItemStyleSection = (isDragging: boolean, draggableStyle: NotDraggingStyle | DraggingStyle | any) => ({
  margin: `0`,
  background: isDragging ? '#fff' : '',
  border: isDragging ? `1px dashed ${appColors.primary}` : '',
  ...draggableStyle,
});

export const DragableSection: React.StatelessComponent<Props> = (props: Props) => {
  const { section, categoryId, articles, index, sectionsStore } = props;
  return (
    <Draggable
      draggableId={
        // eslint-disable-next-line
        section ? section.id! : 'ORPHANS_SECTION'
      }
      index={index}
    >
      {
        // eslint-disable-next-line
        (sectionProvided, sectionSnapshot) => (
          <div
            {...sectionProvided.draggableProps}
            ref={sectionProvided.innerRef}
            style={sortingItemStyleSection(sectionSnapshot.isDragging, sectionProvided.draggableProps.style)}
          >
            {section ? (
              // eslint-disable-next-line
              // @ts-ignore
              <div className="section" {...sectionProvided.dragHandleProps}>
                <SectionMenuItem
                  section={section}
                  sectionsStore={
                    // eslint-disable-next-line
                    sectionsStore!
                  }
                  categoryId={
                    // eslint-disable-next-line
                    categoryId!
                  }
                />
              </div>
            ) : (
              <div className="section-header">
                &nbsp;
                {
                  // eslint-disable-next-line
                  // @ts-ignore
                  <p {...sectionProvided.dragHandleProps} />
                }
              </div>
            )}

            <SectionAddArticle section={section} categoryId={categoryId} />

            <Droppable
              droppableId={
                // eslint-disable-next-line
                section ? section.id! : 'ORPHANS_SECTION'
              }
              type="articles"
            >
              {
                // eslint-disable-next-line
                (articlesProvided, _) => (
                  <div {...articlesProvided.droppableProps} ref={articlesProvided.innerRef}>
                    {articles.length === 0 ? (
                      <div>&nbsp;</div>
                    ) : (
                      articles.map((article: Article, innerIndex: number) => {
                        return (
                          <DragableArticle
                            // eslint-disable-next-line
                            key={article.id!}
                            article={article}
                            section={section}
                            categoryId={categoryId}
                            index={innerIndex}
                          />
                        );
                      })
                    )}

                    {articlesProvided.placeholder}
                  </div>
                )
              }
            </Droppable>
          </div>
        )
      }
    </Draggable>
  );
};
