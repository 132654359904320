import * as React from 'react';
import { RootStore } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { ArticleAdminToolbar } from '../Body/Article/ArticleAdminToolbar';
import { ArticleEditor } from '../Body/Article/ArticleEditor';
import FrameText from '../FrameText/FrameText';
import './AboutInfo.css';

interface Props {
  rootStore?: RootStore;
}

interface State {
  editStatus: boolean;
  content: string;
}

@inject('rootStore')
@observer
export class AboutInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editStatus: false,
      // eslint-disable-next-line
      content: this.props.rootStore!.configurationAboutInfo,
    };
  }

  componentDidMount(): void {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      document.title = `${project.data().name} | StartSida`;
    }
  }
  componentDidUpdate(): void {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      document.title = `${project.data().name} | StartSida`;
    }
  }

  iframe: HTMLIFrameElement;

  edit = (newContent: string): void => {
    this.setState({ content: newContent });
  };

  toggleEdit = (): void => {
    this.setState({ editStatus: !this.state.editStatus });
  };

  save = (): void => {
    this.setState({ editStatus: false, content: this.state.content });
    // eslint-disable-next-line
    this.props.rootStore!.updateConfigurationAboutInfo(this.state.content);
  };

  get isDirty(): boolean {
    // eslint-disable-next-line
    return this.props.rootStore!.configurationAboutInfo !== this.state.content;
  }

  reset = (): void => {
    // eslint-disable-next-line
    this.setState({ editStatus: false, content: this.props.rootStore!.configurationAboutInfo });
  };

  render(): React.ReactNode {
    const { rootStore } = this.props;
    const { editStatus } = this.state;
    // eslint-disable-next-line
    const { userCanEdit, userExist, configurationAboutInfo } = rootStore!;
    const content = this.isDirty && this.state.content ? this.state.content : configurationAboutInfo;

    return (
      <div className="about-info">
        {userCanEdit && (
          <ArticleAdminToolbar
            editStatus={editStatus}
            isDirty={this.isDirty && editStatus}
            onToggleEdit={this.toggleEdit}
            onSave={this.save}
            onReset={this.reset}
          />
        )}

        {editStatus && userExist && (
          <ArticleEditor editStatus={editStatus} content={content} onModelChange={this.edit} />
        )}
        {content && <FrameText content={content} status={!editStatus} />}
      </div>
    );
  }
}
