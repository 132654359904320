import * as React from 'react';
import { ChangeEvent, FormEvent } from 'react';
import { Input, InputGroup } from 'reactstrap';
import { History } from 'history';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { observer, inject } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { localizedStrings } from '../../localizedStrings';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroupText from 'reactstrap/lib/InputGroupText';
import { AppIcon } from '../icons/Icon';

interface Props {
  rootStore?: RootStore;
  articlesStore?: ArticlesStore;
  history: History;
}

@inject('rootStore', 'articlesStore')
@observer
export class Search extends React.Component<Props> {
  timer: number;
  triggerSearchOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    this.triggerSearch(e.target.value);
  };

  triggerSearch = (value: string): void => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      // eslint-disable-next-line
      this.props.history.push(`/${this.props.rootStore!.projectId}/search/${value}`);
      // eslint-disable-next-line
      this.props.articlesStore!.setSearch(value);
    }, 300);
  };

  handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { searchText } = event.currentTarget;
    this.triggerSearch(searchText.value);
  };
  render(): React.ReactNode {
    return (
      <form onSubmit={this.handleSubmit}>
        <InputGroup className="search-box">
          <InputGroupAddon addonType="prepend">
            <InputGroupText id="searchboxicon">
              <AppIcon icon="lens" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="search"
            id="searchText"
            onChange={this.triggerSearchOnChange}
            placeholder={localizedStrings.global.search}
          />
        </InputGroup>
      </form>
    );
  }
}
