import * as React from 'react';
import { Form, Input, FormGroup, Label, Col, Button, Alert } from 'reactstrap';
import { localizedStrings } from '../../localizedStrings';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { RootStore } from '../../stores/RootStore';
import { FormEvent } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { inject } from 'mobx-react';
import './Body.css';

type PathParamsType = {
  projectId: string;
  categoryId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  rootStore?: RootStore;
  categoriesStore?: CategoriesStore;
};
interface State {
  pinCheckValid: boolean | undefined;
  isAuthorized: boolean;
}

@inject('rootStore', 'categoriesStore')
class CategoriesAuthComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pinCheckValid: undefined,
      isAuthorized: false,
    };
  }
  componentDidMount(): void {
    document.title = `${this.props.match.params.projectId} locked:[${this.props.match.params.categoryId}]`;
  }
  componentDidUpdate(): void {
    document.title = `${this.props.match.params.projectId} locked:[${this.props.match.params.categoryId}]`;
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { categoriesStore } = this.props;
    const { pinPassword } = event.currentTarget;
    const { categoryId } = this.props.match.params;

    if (pinPassword.value.trim() !== '') {
      // eslint-disable-next-line
      const pinCheck = categoriesStore!.checkCategoryPin(categoryId, pinPassword.value.trim());

      this.setState({
        pinCheckValid: pinCheck,
      });

      // eslint-disable-next-line
      if (pinCheck && categoriesStore!.userAuthorizedCategories.indexOf(categoryId) === -1) {
        // eslint-disable-next-line
        categoriesStore!.addAuthCategory(categoryId);
        this.setState({ isAuthorized: true });
      }
    }
    return;
  };

  render(): React.ReactNode {
    const { pinCheckValid } = this.state;
    const { categoryId } = this.props.match.params;
    return this.state.isAuthorized ? (
      <Redirect
        to={
          // eslint-disable-next-line
          `/${this.props.rootStore!.projectId}/category/${categoryId}`
        }
      />
    ) : (
      <Col
        xs={{ size: 12 }}
        sm={{ size: 4 }}
        md={{ size: 4 }}
        lg={{ size: 4 }}
        xl={{ size: 4 }}
        className="mr-auto ml-auto"
      >
        <div className="p-5 mt-3 bg-white">
          {pinCheckValid === false ? <Alert color="danger">{localizedStrings.category.pinCheckNotValid} </Alert> : null}
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="examplePassword">
                {localizedStrings.category.password} for {categoryId}
              </Label>
              <Input
                maxLength={8}
                type="password"
                id="pinPassword"
                minLength={4}
                className="form-control category-auth-input"
                autoFocus={true}
              />
            </FormGroup>
            <Button color="primary">{localizedStrings.category.login}</Button>
          </Form>
        </div>
      </Col>
    );
  }
}

export const CategoriesAuth = withRouter(CategoriesAuthComponent);
