import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { localizedStrings } from '../../localizedStrings';
import { AppIcon } from '../icons/Icon';
import './UserNav.css';
import { UIStore } from '../../stores/UIStore';
import { RootStore, userType } from '../../stores/RootStore';
import { toasts } from 'shared';

interface Props {
  uiStore?: UIStore;
  rootStore?: RootStore;
}
interface State {
  userIsAdmin: boolean;
}

@inject('uiStore', 'rootStore')
@observer
export class AppModeSwitcher extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userIsAdmin: false,
    };
  }

  // eslint-disable-next-line
  componentDidMount() {
    // eslint-disable-next-line
    this.props.rootStore!.isUserLegalInProject().then((result: userType) => {
      if (['GLOBAL_ADMIN', 'PROJECT_ADMIN'].includes(result)) {
        this.setState({ userIsAdmin: true });
      }
      if (result === 'ILLEGAL_USER') {
        toasts.error(localizedStrings.auth.wrongProject);
        // eslint-disable-next-line
        this.props.rootStore!.signOut();
      }
    });
  }
  // eslint-disable-next-line
  setPublishMode = () => {
    // eslint-disable-next-line
    this.props.uiStore!.setPublishMode();
  };

  // eslint-disable-next-line
  setEditMode = () => {
    // eslint-disable-next-line
    this.props.uiStore!.setEditMode();
  };

  // eslint-disable-next-line
  render() {
    const { uiStore } = this.props;
    const { userIsAdmin } = this.state;
    const publishModeString = localizedStrings.publish.publishMode;
    // eslint-disable-next-line
    const isEditing = uiStore!.draftMode;

    return isEditing ? (
      <Button onClick={this.setPublishMode} className="mr-1">
        <AppIcon icon="eye" content={publishModeString} />
      </Button>
    ) : userIsAdmin ? (
      <Button onClick={this.setEditMode} className="mr-1">
        <AppIcon icon="fileEdit" content={localizedStrings.publish.editMode} />
      </Button>
    ) : null;
  }
}
