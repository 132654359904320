import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { localizedStrings } from '../../localizedStrings';
import { RootStore } from '../../stores/RootStore';
import { AppIcon } from '../icons/Icon';
import { toasts } from 'shared';

interface Props {
  rootStore?: RootStore;
}
interface State {
  emailInput: string;
}

@inject('rootStore')
@observer
export class EmailSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emailInput: '',
    };
  }

  addEmail = (email: string): void => {
    // eslint-disable-next-line
    this.props.rootStore!.addConfigurationFeedbackEmail(email);
  };

  deleteEmail = (email: string): void => {
    // eslint-disable-next-line
    this.props.rootStore!.deleteConfigurationFeedbackEmail(email);
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { email } = event.currentTarget;
    if (this.ValidEmail) {
      this.addEmail(email.value);
      this.setState({ emailInput: '' });
    } else {
      toasts.error(localizedStrings.settings.emailError);
    }
  };

  get ValidEmail(): boolean {
    // currently just checking emptiness. TODO: maybe regex for email validation
    return this.state.emailInput.trim() !== '';
  }

  onChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  render(): React.ReactNode {
    const { rootStore } = this.props;

    return (
      <div className="p-3">
        <h3 className="pb-3">{localizedStrings.settings.emailSettings}</h3>
        <div className="col-md-6">
          <form onSubmit={this.handleSubmit}>
            {
              // eslint-disable-next-line
              rootStore!.userCanEdit && (
                <div className="form-group">
                  <p>{localizedStrings.settings.addEmailsHint}</p>
                  <input
                    placeholder={localizedStrings.settings.email}
                    className="form-control"
                    type="email"
                    id="email"
                    value={this.state.emailInput}
                    name="emailInput"
                    onChange={this.onChange}
                  />
                </div>
              )
            }
          </form>

          <table className="table">
            <thead>
              <tr>
                <th>{localizedStrings.settings.email}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                // eslint-disable-next-line
                rootStore!.configurationFeedbackEmails.map((email, i) => (
                  <tr key={email + i}>
                    <th>{email}</th>
                    <td>
                      {
                        // eslint-disable-next-line
                        rootStore!.userCanEdit && (
                          <AppIcon
                            icon="trash"
                            title={localizedStrings.global.delete}
                            onClick={(): void => this.deleteEmail(email)}
                          />
                        )
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
