import { ApplicationStyle, Article } from '@eir/core';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { localizedStrings } from '../../../localizedStrings';
import { ArticlesStore } from '../../../stores/ArticlesStore';
import { RootStore } from '../../../stores/RootStore';
import { ThemeContext } from '../../App/App';
import { AppIcon } from '../../icons/Icon';
import { ArticleDelete } from './ArticleDelete';
import { ArticleEditName } from './ArticleEditName';

interface Props {
  articlesStore?: ArticlesStore;
  rootStore?: RootStore;
  categoryId: string;
  article: Article;
}

interface State {
  actionsVisible: boolean;
  editName: boolean;
  article: Article;
}

@inject('rootStore', 'articlesStore')
@observer
export class ArticleActions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      actionsVisible: false,
      editName: false,
      article: this.props.article,
    };
  }

  handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>): void => {
    this.setState({ actionsVisible: true });
    event.stopPropagation();
  };

  handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>): void => {
    this.setState({ actionsVisible: false });
    event.stopPropagation();
  };

  activateEditName = (): void => {
    this.setState({
      editName: true,
    });
  };

  hideEdit = (): void => {
    this.setState({
      editName: false,
    });
  };

  render(): React.ReactNode {
    const { rootStore, articlesStore } = this.props;
    const { article, editName, actionsVisible } = this.state;
    // eslint-disable-next-line
    const isActive = articlesStore!.activeArticle && articlesStore!.activeArticle === article.id;

    return (
      <ThemeContext.Consumer>
        {
          // eslint-disable-next-line
          (appStyle: ApplicationStyle) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center p-1 ml-2"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                style={{ backgroundColor: isActive ? appStyle.primaryLight : '' }}
              >
                {editName ? (
                  <ArticleEditName article={article} onHideEdit={this.hideEdit} categoryId={this.props.categoryId} />
                ) : (
                  <Link
                    className="col-sm-10"
                    // eslint-disable-next-line
                    to={`/${rootStore!.projectId}/category/${article.data().category}/${article.id}`}
                  >
                    <span className="text-dark-gray"> {article.data().name} </span>
                  </Link>
                )}

                {actionsVisible && !editName && (
                  <span className="d-flex default-cursor pr-2">
                    <AppIcon icon="fileEdit" title={localizedStrings.global.edit} onClick={this.activateEditName} />

                    <ArticleDelete article={article} categoryId={this.props.categoryId} />
                  </span>
                )}
              </div>
            );
          }
        }
      </ThemeContext.Consumer>
    );
  }
}
