import * as React from 'react';
import { Button } from 'reactstrap';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import { localizedStrings } from '../../../localizedStrings';
import { AppIcon } from '../../icons/Icon';

interface Props {
  editStatus: boolean;
  isDirty: boolean;
  onToggleEdit: () => void;
  onSave: () => void;
  onReset: () => void;
}

const styles = 'd-flex p-2 justify-content-end';

export const ArticleAdminToolbar: React.StatelessComponent<Props> = (props: Props) => {
  return (
    <div className={props.editStatus ? `${styles} bg-dark-gray` : styles}>
      {props.isDirty && (
        <ButtonGroup>
          <Button color="danger" className="mr-1" onClick={props.onReset}>
            {localizedStrings.global.cancel}
          </Button>
          <Button color="primary" className="mr-1" onClick={props.onSave}>
            {localizedStrings.global.save}
          </Button>
        </ButtonGroup>
      )}
      <Button color="primary mr-1" onClick={props.onToggleEdit}>
        {props.editStatus ? (
          <AppIcon icon="eye" color="white" content={localizedStrings.global.preview} />
        ) : (
          <AppIcon icon="fileEdit" color="white" content={localizedStrings.global.edit} />
        )}
      </Button>
    </div>
  );
};
