import * as React from 'react';
import { FormEvent } from 'react';
import { Section, ApplicationStyle } from '@eir/core';
import { SectionsStore } from '../../../stores/SectionsStore';
import Button from 'reactstrap/lib/Button';
import { localizedStrings } from '../../../localizedStrings';
import { MAX_SECTION_NAME_LENGTH } from './SectionAdd';
import { ModalConfirm } from './ModalConfirm';
import { inject, observer } from 'mobx-react';
import { ThemeContext } from '../../App/App';
import { AppIcon } from '../../icons/Icon';
import { toasts } from 'shared';
import { CategoriesStore } from 'stores/CategoriesStore';

interface Props {
  section: Section;
  sectionsStore?: SectionsStore;
  categoriesStore?: CategoriesStore;
  categoryId: string;
}

interface State {
  editing: boolean;
  saveEnabled: boolean;
  modalOpened: boolean;
  inputText: string;
}
/**
 * Edit Section name, Delete Section, Add Article to Section
 */
@inject('sectionsStore', 'categoriesStore')
@observer
export class SectionEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
      saveEnabled: false,
      modalOpened: false,
      inputText: props.section.data().name,
    };
  }

  submit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    // eslint-disable-next-line
    const isChief: boolean = this.props.categoriesStore!.isCategoryChief(this.props.categoryId) ? true : false;
    const isChangelog: boolean = this.props.categoryId === 'changelog';
    const { inputText } = this.state;
    const validName = inputText.trim() !== '' && inputText.length < MAX_SECTION_NAME_LENGTH;
    if (validName) {
      this.setState({ editing: false });
      // eslint-disable-next-line
      this.props.sectionsStore!.updateSection(String(this.props.section.id), inputText, isChief, isChangelog);
    } else {
      toasts.error(localizedStrings.section.wrongSectionName);
    }
  };

  cancel = (): void => {
    this.setState({ editing: false, inputText: this.props.section.data().name });
  };

  setEdit = (): void => {
    this.setState({ editing: true });
  };

  showModal = (): void => this.setState({ modalOpened: true });

  cancelModal = (): void => this.setState({ modalOpened: false });

  deleteSection = (): void => {
    // eslint-disable-next-line
    const isChief: boolean = this.props.categoriesStore!.isCategoryChief(this.props.categoryId) ? true : false;
    const isChangelog: boolean = this.props.categoryId === 'changelog';
    const { sectionsStore, categoryId, section } = this.props;
    // eslint-disable-next-line
    sectionsStore!.deleteSection(String(section.id), String(categoryId), isChief, isChangelog);
    this.setState({ modalOpened: false });
  };
  // eslint-disable-next-line
  onInputChange = (e: any): void => {
    // eslint-disable-next-line
    this.setState({ inputText: e!.target.value });
  };

  render(): React.ReactNode {
    const { editing, inputText } = this.state;
    const { section } = this.props;
    const isDisabled = !inputText || inputText === this.props.section.data().name;

    return (
      <ThemeContext.Consumer>
        {(appStyle: ApplicationStyle): React.ReactNode => {
          return (
            <div className="d-flex justify-content-between align-items-center p-1">
              <ModalConfirm
                opened={this.state.modalOpened}
                onYes={this.deleteSection}
                onCancel={this.cancelModal}
                header={localizedStrings.section.deleteSectionModalHeader}
                body={localizedStrings.section.deleteSectionModalBody}
                actionButton={localizedStrings.global.delete}
              />
              {editing ? (
                <form onSubmit={this.submit} className=" d-flex justify-content-between align-items-center flex-grow-1">
                  <input id="nameInput" onChange={this.onInputChange} value={inputText} className="col-sm-8" />
                  <span className="d-flex default-cursor pr-2">
                    <Button type="submit" disabled={isDisabled} outline={true}>
                      <AppIcon icon="check" title={localizedStrings.global.save} />
                    </Button>

                    <Button onClick={this.cancel} outline={true}>
                      <AppIcon icon="cancel" title={localizedStrings.global.cancel} />
                    </Button>
                  </span>
                </form>
              ) : (
                <div className=" d-flex justify-content-between align-items-center flex-grow-1">
                  <div className="col-sm-10" style={{ color: appStyle.primaryDark, fontWeight: 'bolder' }}>
                    {section.data().name}
                  </div>

                  <span className="d-flex default-cursor pr-2">
                    <AppIcon icon="fileEdit" title="edit-section" onClick={this.setEdit} />
                    <AppIcon icon="trash" onClick={this.showModal} title={localizedStrings.global.delete} />
                  </span>
                </div>
              )}
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}
