import * as React from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import { inject } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import './ExternalContent.css';
import { CategoriesStore } from 'stores/CategoriesStore';
import { Project, ExternalDoc } from '@eir/core';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { isEqual } from 'lodash';

declare global {
  // eslint-disable-next-line
  interface Window {}
}

type PathParamsType = {
  folder: string;
  projectId: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  rootStore?: RootStore;
  categoriesStore?: CategoriesStore;
  articlesStore?: ArticlesStore;
};

interface State {
  externalDoc: ExternalDoc | null;
}

@inject('rootStore', 'categoriesStore', 'articlesStore')
class ExternalContentComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      externalDoc: null,
    };
  }

  public fetchExternalDoc = async (id: string): Promise<void> => {
    // eslint-disable-next-line
    const externalDoc: ExternalDoc = await this.props.articlesStore!.loadExternalDoc(id);
    this.setState({ externalDoc });
  };

  componentDidMount(): void {
    // eslint-disable-next-line
    this.props.articlesStore!.setCurrentArticle('');
    this.fetchExternalDoc(this.props.match.params.folder);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (!isEqual(prevProps.match.params.folder, this.props.match.params.folder)) {
      this.fetchExternalDoc(this.props.match.params.folder);
    } else if (!isEqual(this.state.externalDoc, prevState.externalDoc)) {
      const extFrame: HTMLIFrameElement = document.getElementById('externalframe') as HTMLIFrameElement;
      if (extFrame) {
        const theDoc = extFrame.contentDocument;
        // eslint-disable-next-line
        theDoc!.open();
        // eslint-disable-next-line
        theDoc!.write(this.state.externalDoc!.data().content);
        // eslint-disable-next-line
        theDoc!.close();
        // eslint-disable-next-line
        theDoc!.addEventListener('click', this.linkInterceptor);
      }
    }
  }

  cannotBeAccessed = (): boolean => {
    const { categoriesStore, rootStore } = this.props;
    const { folder } = this.props.match.params;
    // eslint-disable-next-line
    return categoriesStore!.userAuthorizedCategories.indexOf(folder) > -1 && !rootStore!.userExist;
  };

  setDocTitle(project: Project): void {
    document.title = `${project.data().name} | ${this.props.match.params.folder}`;
  }

  render(): React.ReactNode {
    // eslint-disable-next-line
    const { project } = this.props.rootStore!;
    if (project) {
      this.setDocTitle(project);
    }

    return (
      <>
        {this.cannotBeAccessed() ? (
          <Redirect
            to={
              // eslint-disable-next-line
              `/${this.props.rootStore!.projectId}`
            }
          />
        ) : (
          <iframe
            title="external"
            id="externalframe"
            className="external-content"
            sandbox="allow-forms allow-scripts allow-same-origin"
          />
        )}
      </>
    );
  }

  private linkInterceptor = (event: Event): void => {
    // TODO: include other type of links handling
    // OBS!!! The interseptor currently only handles the link to internal articles
    event.preventDefault();
    // eslint-disable-next-line
    const element = event.target! as HTMLAnchorElement;
    const link = element.href;
    if (link) {
      const url = new URL(link);
      const routeTo = `/${this.props.match.params.projectId}${url.pathname}`;
      this.props.history.push(routeTo);
    } else {
      return;
    }
  };
}

export const ExternalContent = withRouter(ExternalContentComponent);
