import { action, computed, observable } from 'mobx';

const APP_CURRENT_MODE_KEY = 'APP_MODE';

export class UIStore {
  isInDraftMode = observable.box(false);

  @observable
  sorting: string | 'articles' | 'sections' = 'articles';

  constructor() {
    const currentMode = localStorage.getItem(APP_CURRENT_MODE_KEY);
    this.isInDraftMode.set(currentMode ? JSON.parse(currentMode) : false);
  }

  @action.bound
  setSorting(sorting: string | 'articles' | 'sections'): void {
    this.sorting = sorting;
  }

  @computed
  get selectedSorting(): string {
    return this.sorting;
  }

  @computed
  get isSortingSection(): boolean {
    return this.selectedSorting === 'sections';
  }

  @computed
  get draftMode(): boolean {
    return this.isInDraftMode.get();
  }

  @action.bound
  setPublishMode(): void {
    this.isInDraftMode.set(false);
    localStorage.setItem(APP_CURRENT_MODE_KEY, String(false));
  }

  @action.bound
  setEditMode(): void {
    this.isInDraftMode.set(true);
    localStorage.setItem(APP_CURRENT_MODE_KEY, String(true));
  }

  @action
  clearLocalStorage(): void {
    localStorage.removeItem(APP_CURRENT_MODE_KEY);
  }
}
